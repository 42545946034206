(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('EditorPhotoPreviewController', EditorPhotoPreviewController);

    EditorPhotoPreviewController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'WxJS', 'Gallery', 'ItemValue', 'SweetAlert', 'Theme', '$localStorage', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'Store', 'Article', 'CommonService', '$http', 'Folder','MessageNotification', 'Product', 'Music', 'StoreConfig',
        'Cart', 'Iphone','SalesOrder','OrderArticle','PreviewSettings', '$uibModal','MessageService'];

    function EditorPhotoPreviewController($state, $scope, $q, $stateParams, Convert, Qinius, WxJS, Gallery, ItemValue, SweetAlert, Theme, $localStorage, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, Store, Article, CommonService, $http, Folder,MessageNotification, Product, Music, StoreConfig,
                                          Cart, Iphone,SalesOrder, OrderArticle,PreviewSettings, $uibModal,MessageService) {
        //初始化
        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});

        var vm = this;
        vm.messageShow = true;
        //区别小程序、公众号
        //区别小程序、公众号
        if (window.location.href.indexOf("?") > -1) {
            localStorage.setItem('themeFlag', 'two');
            // localStorage.removeItem('guide-step');
            var r = window.location.href.split('?')[1];
            var typeFlag = r.split('&')[0].split('=')[1];
            var authorize = r.split('&')[1].split('=')[1];
            if(r.split('&')[2]){
                var productionType = r.split('&')[2].split('=')[1];
            }
            if(r.split('&')[3]){
                var formLibrary = r.split('&')[3].split('=')[1];
            }
            var orderOrigin = null;
            if (r.split('&').length>=5) {
                orderOrigin = r.split('&')[4].split('=')[1];
            }

            orderOrigin = decodeURIComponent(orderOrigin);
            var distinguish = decodeURIComponent(typeFlag);
            var tempAuthorization = decodeURIComponent(authorize);
            var authorization = tempAuthorization.slice(7, tempAuthorization.length);
            var showFlag = decodeURIComponent(productionType);
            var editLibrary = decodeURIComponent(formLibrary);
            if (distinguish == 'weChatApplet' || distinguish == 'weChatAppletMake' || distinguish == 'toutiaoApp' || distinguish == 'alipayApp' || distinguish == 'jdApp') {
                localStorage.setItem('orderOrigin', orderOrigin);
                localStorage.setItem('distinguish', distinguish);
                $localStorage.authenticationToken = authorization;
            }
            vm.target=getQueryVariable('target');
            localStorage.setItem('target', vm.target);
        }else{
            vm.target = localStorage.getItem('target');
        }
        vm.storeId = $stateParams.storeId;
        vm.aid = $stateParams.aid;
        vm.salesOrderId = $stateParams.soid ? $stateParams.soid : null;
        vm.productLineId = $stateParams.plid ? $stateParams.plid : null;

        $scope.domains = Qinius.getDomain1();
        vm.platformSetting = PlatformWechatSetting.get();
        vm.store = Store.get({id: vm.storeId});
        vm.requireUserProtocol = false;
        if(vm.salesOrderId){
            SalesOrder.get({id:vm.salesOrderId}, function (res) {
                vm.salesOrder = res;
            })
        }

        vm.storeCart = StoreConfig.byStoreIdAndType({
            storeId: vm.storeId,
            type:'SHOPPINGCART'
        });
        StoreConfig.byStoreIdAndType({storeId:vm.storeId, type:"USERPROTOCOL"}, function (res) {
            if (res && res.type == "USERPROTOCOL") {
                vm.requireUserProtocol = res.open || false;
            }
        });

        vm.bcImg = "content/images/view_bg.png";
        PreviewSettings.byStoreId({storeId: vm.storeId},function (res) {
            vm.defaultbg = res.message.default;
            if(!res.message.default){
                vm.bcImg = res.message.bcImg;
            }
        });
        function getQueryVariable(variable) {
            var query = window.location.hash.substring(1);
            var _a = query.split("?")[1]
            var vars = _a.split("&");
            for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
            }
            return(false);
        }
        $q.all([$scope.domains.$promise, vm.store.$promise]).then(function () {
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            vm.isIPhoneX = Iphone;
            init();
            getSalesOrders();
            document.addEventListener('UniAppJSBridgeReady', function() {})
            if(vm.store.orderWay=='orderThenEditor' && vm.salesOrderId){
                getSubmittable();
            }
        });
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[1];
            return arrDPI;
        }
        function fn($event) {
            var $event = $event || window.event;
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
        }

        vm.isSubmittable = false;
        function getSubmittable() {
            SalesOrder.submittable({id: vm.salesOrderId},function (res) {
                if(res.status == 200 && res.message == true){
                    vm.isSubmittable = true;
                }
            })
        }
        $scope.submitBackWorks = function () {

            if (vm.store.orderWay == 'orderThenEditor' && vm.requireUserProtocol){
                checkAgreement(vm.salesOrderId, function() {
                    requestSubmit(vm.salesOrderId);
                });
                return;
            } else {
                requestSubmit(vm.salesOrderId);
            }
        }

        function requestSubmit(orderId) {
            SalesOrder.submit({
                id: orderId
            }, function(data) {
                swal({
                    title: "提交成功",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                $scope.backWorks(true);
            }, function() {
                swal({
                    title: "提交失败!",
                    text: error.data.message,
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            })
        }

        function checkAgreement(orderId, agreeCallback) {
            SalesOrder.get({id: orderId}, function (order) {
                var quantity = 0;
                for (var i = 0; i < order.productLines.length; i++) {
                    var line = order.productLines[i];
                    quantity += (line.copy || 0);
                }
                $uibModal.open({
                    templateUrl: 'app/entities/sales-order/modal/protocol-agreement2.html',
                    controller: 'OrderProtocolAgreement2Controller',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: [function () {
                            var _obj = {
                                number: order.number,
                                quantity: quantity
                            }
                            return _obj;
                        }]
                    }
                }).result.then(function (data) {
                    if (data && data.agree) {
                        if (agreeCallback) {
                            agreeCallback();
                        }
                    }
                });
            });
        }

        //主页
        function init(){
            Article.get({id: vm.aid}, function (res) {
                vm.article = res;
                $scope.template = angular.copy(res.document);
                getItemValue(function (){
                    abook()
                });
                $(document).attr("title",vm.article.name);
            }, function () {
                OrderArticle.get({id: vm.aid}, function (res) {
                    vm.article = res;
                    vm.isOrderArticle = true;
                    $scope.template = angular.copy(res.document);
                    getItemValue(function (){
                        abook()
                    });
                    $(document).attr("title",vm.article.name);
                })
            })

            var monthStr = new Date().getMonth() + 1;
            var dayStr = new Date().getDate();
            var timestr = new Date().getFullYear() + "-" + (monthStr > 9 ? monthStr : "0" + monthStr) + "-" + (dayStr > 9 ? dayStr : "0" + dayStr);
            MessageNotification.getAllByCondition2({
                items: [{key: "available", op: "=", value: "true"}, {
                    key: "validate",
                    op: ">=",
                    value: timestr
                }, {key: "pageType", op: "=", value: "View"}]
            }, function (res) {
                res.sort(compare('seq'))
                vm.message = angular.copy(res);
                vm.messageText = "\xa0\xa0\xa0\xa0\xa0\xa0";
                angular.forEach(res, function (resp,index) {
                    vm.messageText += (index+1)+ ':' + resp.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                })

                vm.messageShow = res.length > 0 ? true : false;
            });


            Music.allByCondition({
                items: [],
                sort: ["id,asc"]
            }, function (resp) {
                $scope.Musics = resp;
            });
        }

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }
        vm.isHideMusicBtn = false;
        vm.isPlayBack = false;
        var audio = document.getElementById('audio');
        var timer = null;
        var rot = 10 - 0;
        function getItemValue(callback){
            ItemValue.get({id: vm.article.itemValueId}, function (msg) {
                vm.pictureType = msg.pictureType || 'single';
                vm.itemValue = msg;
                $scope.price = msg.price;

                if (vm.article.musicIdentify) {
                    audio.src = $scope.domain + vm.article.musicIdentify;
                } else {
                    if (msg.musicIdentify) {
                        audio.src = $scope.domain + msg.musicIdentify;
                    } else {
                        audio.src = '';
                        vm.isHideMusicBtn = true;
                    }
                }
                if(!vm.isHideMusicBtn){
                    bf()
                }
                if(callback){callback()}
            });
        }
        $scope.playBack = function () {
            bf()
        }
        function bf(){
            if(audio.paused){
                audio.play();
                vm.isPlayBack = true;
            }else{
                audio.pause();
                vm.isPlayBack = false;
            }
            if(vm.isPlayBack){
                window.clearInterval(timer);
                timer = window.setInterval(begin, 10);
            }else {
                window.clearInterval(timer);
            }
        }
        function begin(time) {
            var micon = document.getElementById("music-icon");
            if (micon == null) {
                window.clearInterval(timer);
                return;
            }
            micon.style.transform = "rotate(" + rot + "deg)";
            rot += 1;
        }
        vm.pictureType = 'single';
        function abook() {
            vm.isEdit = true;
            wechat();
            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){}, function () {
                $timeout(function () {
                    var windowRatio = 0.4;
                    var custom = $scope.template.pages.page[0].custom;
                    vm.pageWidth = custom.width;
                    vm.pageHeight = custom.height;
                    vm.ratio = $scope.dWidth * windowRatio / vm.pageWidth;
                    var documentPage = $scope.template.pages.page;
                    vm.page = [];
                    if (vm.pictureType == 'single') {
                        for (var i = 0; i < documentPage.length; i++) {
                            if(!documentPage[i].backup){
                                var isRepetition = false;
                                for (var j = 0; j < vm.page.length; j++) {
                                    if (documentPage[i].uuid === vm.page[j].uuid) {
                                        isRepetition = true;
                                        vm.page[j].quantity++
                                        break;
                                    }
                                }
                                if (!isRepetition) {
                                    documentPage[i].quantity = 1;
                                    vm.page.push(documentPage[i])
                                }
                            }
                        }
                        ;
                    }
                    if (vm.pictureType == 'spread') {
                        var _data = [];
                        for (var i = 0; i < documentPage.length; i++) {
                            var _documentPage2 = documentPage[i];
                            if(!_documentPage2.backup){
                                var isRepetition1 = false;
                                if (i % 2 === 0) {
                                    //偶数
                                    for (var j = 0; j < vm.page.length; j++) {
                                        if (_documentPage2.uuid === vm.page[j][0].uuid) {
                                            vm.page[j][0].quantity++;
                                            isRepetition1 = true;
                                            break;
                                        }
                                    }
                                    if (!isRepetition1) {
                                        _documentPage2.quantity = 1;
                                        _data.push(_documentPage2);
                                    }
                                } else if (i % 2 === 1) {
                                    //奇数
                                    if (_data.length > 0) {
                                        _data.push(_documentPage2);
                                        vm.page.push(_data);
                                        _data = [];
                                    }
                                }
                            }
                        }
                        ;
                    }
                }, 1000)
            })
        }
        function wechat() {

            if (wx) {
                var url = "";
                if (!!window.location.hash ) {
                    url = window.location.href.slice(0, -window.location.hash.length)
                }else{
                    url =  window.location.href;
                }

                WxJS.get2({
                    url: url, storeId: vm.storeId
                }, function (config) {
                    if (window.location.href.indexOf("?") == -1) {
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.appId, // 必填，公众号的唯一标识
                            timestamp: config.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.nonceStr, // 必填，生成签名的随机串
                            signature: config.signature,// 必填，签名，见附录1
                            jsApiList: [
                                "checkJsApi",
                                "onMenuShareTimeline",
                                "onMenuShareAppMessage",
                                "onMenuShareQQ",
                                "onMenuShareQZone",
                                'showOptionMenu',
                                "onMenuShareWeibo"
                            ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });

                        // 异步处理，方法堆放一起
                        wx.ready(function () {
                            wx.showOptionMenu();
                            wx.checkJsApi({
                                jsApiList: [
                                    "checkJsApi",
                                    "onMenuShareTimeline",
                                    "onMenuShareAppMessage",
                                    "onMenuShareQQ",
                                    "onMenuShareQZone",
                                    "onMenuShareWeibo"
                                ],
                                success: function (res) {
                                    wx.onMenuShareTimeline({
                                        title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                        link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/editorPhotoPreview1", // 分享链接
                                        imgUrl: $scope.domain + vm.article.cover,
                                        desc: '随意创作属于自己独一无二的作品',
                                        success: function (res) {
                                            Article.shareCallBack({id:vm.article.id})
                                        },
                                        cancel: function (res) {
                                        },
                                        fail: function (res) {
                                        }
                                    });
                                    //朋友
                                    wx.onMenuShareAppMessage({
                                        title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                        desc: '随意创作属于自己独一无二的作品', // 分享描述
                                        link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/editorPhotoPreview1", // 分享链接
                                        imgUrl: $scope.domain + vm.article.cover,
                                        type: 'link', // 分享类型,music、video或link，不填默认为link
                                        dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                                        success: function () {
                                            Article.shareCallBack({id:vm.article.id})
                                        },
                                        cancel: function () {
                                        }
                                    });
                                    wx.onMenuShareQQ({
                                        title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                        desc: '随意创作属于自己独一无二的作品', // 分享描述
                                        link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/editorPhotoPreview1", // 分享链接
                                        imgUrl: $scope.domain + vm.article.cover, // 分享图标
                                        success: function () {
                                            // 用户确认分享后执行的回调函数
                                            Article.shareCallBack({id:vm.article.id})
                                        },
                                        cancel: function () {
                                            // 用户取消分享后执行的回调函数
                                        }
                                    });
                                    wx.onMenuShareQZone({
                                        title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                        desc: '随意创作属于自己独一无二的作品', // 分享描述
                                        link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/editorPhotoPreview1", // 分享链接
                                        imgUrl: $scope.domain + vm.article.cover, // 分享图标
                                        success: function () {
                                            // 用户确认分享后执行的回调函数
                                            Article.shareCallBack({id:vm.article.id})
                                        },
                                        cancel: function () {
                                            // 用户取消分享后执行的回调函数
                                        }
                                    });
                                    wx.onMenuShareWeibo({
                                        title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                        desc: '随意创作属于自己独一无二的作品', // 分享描述
                                        link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/editorPhotoPreview1", // 分享链接
                                        imgUrl: $scope.domain + vm.article.cover, // 分享图标
                                        success: function () {
                                            // 用户确认分享后执行的回调函数
                                            Article.shareCallBack({id:vm.article.id})
                                        },
                                        cancel: function () {
                                            // 用户取消分享后执行的回调函数  
                                        }
                                    });
                                }
                            });
                        });
                    } else {
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: config.appId, // 必填，公众号的唯一标识
                            timestamp: config.timestamp, // 必填，生成签名的时间戳
                            nonceStr: config.nonceStr, // 必填，生成签名的随机串
                            signature: config.signature, // 必填，签名，见附录1
                            jsApiList: ['hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function () {
                            wx.hideMenuItems({
                                menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                            });
                        });
                    }
                    wx.error(function (res) {
                    });
                }, function () {
                });
            }
        }

        //mm转px
        function transitionPx(item) {
            return item * vm.windowDpi / 25.4;
        }
        //px转mm
        function transitionMm(item) {
            return item * 25.4 / vm.windowDpi;
        }
        $scope.openMusicList = function () {
            $scope.musicPage = true;
            vm.musicIdentify = angular.copy(vm.article.musicIdentify);
        };
        $scope.backTo = function(){
            if(vm.article.musicIdentify != vm.musicIdentify){
                audio.src = $scope.domain + vm.article.musicIdentify;
            }
            $scope.musicPage = false;
        }
        $scope.playList = function (identify, index) {
            // audioTest.pause();
            audio.src = $scope.domain + identify;
            vm.musicIdentify = identify;
        };
        $scope.saveMusic = function(){
            if(vm.article.musicIdentify != vm.musicIdentify){
                vm.article.musicIdentify = vm.musicIdentify;
                audio.src = $scope.domain + vm.article.musicIdentify;
                $scope.playBack()
            }
            $scope.musicPage = false;
        }
        // $scope.goToCustomer = function () {
        //     if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake') {
        //         uni.redirectTo({
        //             url: '/pages/information/myInformation/myInformation'
        //         })
        //
        //     } else {
        //         if (localStorage.getItem('themeFlag') == 'two' || localStorage.getItem('themeFlag') == 'three') {
        //             window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/customerTwo';
        //         } else {
        //             window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/customer';
        //         }
        //     }
        // };
        $scope.singleSave = function () {
            vm.data = vm.singleData;
            vm.page1 = true;
            vm.page3 = false;
        };

        //加入购物车
        // vm.itemValue
        vm.isSkuShow = false;
        $scope.shopping = function () {
            if (!vm.article.completed) {
                if(vm.store.orderWay == 'editorThenOrder' && vm.article.jhiPackage){
                    MessageService.hint("当前冲印张数为" + (vm.pictureType == 'spread'?vm.article.pageCount/2:vm.article.pageCount) + ",需要达到"+vm.article.packageQuantity+"的倍数才可下单");
                }else{
                    MessageService.hint("该作品还未完成");
                }
                return;
            }
            var target = localStorage.getItem('target');
            if(target == 'commandMini'){
                uni.navigateTo({
                    url: "/pages/shop/placeOrder/placeOrder?jsonStr={\"id\":" + vm.aid + "}&from=productIntroduce"
                });
                return
            }
            vm.isSkuShow = true;
            // vm.product ={};
            // vm.property =[];
            // vm.itemValueLine = null;
            // vm.shoppingCart ={};
            // vm.price ="";
            // vm.quantity = vm.itemValue.minimumOrder || 1;
            // getProduct()
        }

        function getProduct() {
            Product.get({id:vm.article.productId}, function (res) {
                vm.product = res;
                getProperty()
            })
        };
        function getProperty(){
            for(var i=0; i<vm.product.objectProperties.length; i++){
                if(!vm.product.objectProperties[i].cancelled && vm.product.objectProperties[i].propertyType === 'Expanded'){
                    var _property = {
                        name: vm.product.objectProperties[i].propertyName,
                        propertyType: vm.product.objectProperties[i].propertyType,
                        values:[]
                    };
                    for(var j=0; j<vm.product.objectProperties[i].propertyValues.length; j++){
                        var _propertyValues = vm.product.objectProperties[i].propertyValues[j];
                        if(!_propertyValues.cancelled){
                            _propertyValues.propertySku = _propertyValues.propertyId + '_' + _propertyValues.id;
                            _property.values.push(_propertyValues)
                        }
                    }
                    vm.property.push(_property)
                }
            };
            if(vm.property.length>0){
                itemValue();
            }else{
                var itemValueLine = null;
                for(var i=0; i<vm.product.itemValues.length; i++){
                    var _itemValues = vm.product.itemValues[i];
                    if(!_itemValues.cancelled && _itemValues.id === vm.itemValue.id){
                        for(var i1=0; i1<_itemValues.itemValueLines.length; i1++){
                            var _itemValueLines = _itemValues.itemValueLines[i1];
                            if(!_itemValueLines.cancelled && _itemValueLines.skuNumber == vm.itemValue.skuNumber){
                                itemValueLine = _itemValueLines;
                            }
                        }
                    }
                };
                vm.itemValueLine = itemValueLine;
                getPrice();
            }
        };
        function itemValue(){
            for(var i=0; i<vm.product.itemValues.length; i++){
                var _itemValues = vm.product.itemValues[i];
                if(!_itemValues.cancelled && _itemValues.id === vm.itemValue.id){
                    for(var i1=0; i1<_itemValues.itemValueLines.length; i1++){
                        var _itemValueLines = _itemValues.itemValueLines[i1];
                        if(_itemValueLines.saleable){
                            for(var i2 = 0; i2<vm.property.length; i2++){
                                var _property = vm.property[i2];
                                for(var i3=0; i3<_property.values.length; i3++){
                                    var _propertyValues = _property.values[i3];
                                    if(_itemValueLines.skuNumber.indexOf(_propertyValues.propertySku) >= 0 && !
                                        _propertyValues.selectable){
                                        _propertyValues.selectable = true;
                                        _propertyValues.show = true;
                                    }
                                }
                            }
                        }
                    }
                }
            };
        };
        $scope.propertySelect = function(parentIndex, index, item){
            var _propertySku = [];
            var _sku = [];
            if(!item.selectable){
                return
            }
            for(var a=0; a<vm.property[parentIndex].values.length; a++){
                if(a == index){
                    vm.property[parentIndex].values[a].selected = !vm.property[parentIndex].values[a].selected ? true : false;
                }else{
                    vm.property[parentIndex].values[a].selected = false;
                }
            }
            for(var b=0; b<vm.property.length; b++){
                for(var b1=0; b1<vm.property[b].values.length; b1++){
                    if(vm.property[b].values[b1].selected){
                        _propertySku.push(vm.property[b].values[b1].propertySku)
                    }
                }
            }
            if(_propertySku.length>0){
                var _dataProperty = itemValue1(_propertySku);
                _sku = _dataProperty._sku;
                for(var j = 0; j<vm.property.length; j++){
                    var _property = vm.property[j];
                    for(var j1=0; j1<_property.values.length; j1++){
                        var _propertyValues = _property.values[j1];
                        _propertyValues.selectable = false;
                    }
                }


                for(var i2 = 0; i2<vm.property.length; i2++){
                    var _property1 = vm.property[i2];
                    for(var i3=0; i3<_property1.values.length; i3++){
                        var _propertyValues1 = _property1.values[i3];
                        if(!_propertyValues1.selected){
                            var _propertySku1 = _propertyValues1.propertySku;
                            for(var i4=0; i4<_sku.length; i4++){
                                if(_sku[i4].indexOf(_propertySku1) >= 0){
                                    _propertyValues1.selectable = true;
                                }
                            }
                        }else{
                            _propertyValues1.selectable = true;
                        }
                    }
                }
                vm.itemValueLine = _dataProperty.itemValueLines;
                vm.price="";
                if(vm.itemValueLine){
                    getPrice()
                }
            }else{
                vm.itemValueLine = "";
                vm.price="";
                itemValue();
            }

        };
        function itemValue1(propertySku){
            var _data = {
                _sku:[],
                itemValueLines: null
            }
            for(var i=0; i<vm.product.itemValues.length; i++){
                var _itemValues = vm.product.itemValues[i];
                if(!_itemValues.cancelled && _itemValues.id === vm.itemValue.id){
                    for(var i1=0; i1<_itemValues.itemValueLines.length; i1++){
                        var _itemValueLines = _itemValues.itemValueLines[i1];
                        if(!_itemValueLines.cancelled){
                            if(_data._sku.indexOf(_itemValueLines.skuNumber) == -1){
                                var _propertyLength = 0
                                var isItemValueLines = true;
                                for(var i2 =0; i2<propertySku.length; i2++){
                                    if(_itemValueLines.skuNumber.indexOf(propertySku[i2]) >= 0){
                                        _propertyLength++
                                    }else{
                                        isItemValueLines = false
                                    }
                                }
                                if(isItemValueLines){
                                    _data._sku.push(_itemValueLines.skuNumber)
                                }
                                if(_propertyLength == vm.property.length){
                                    _data.itemValueLines = _itemValueLines;
                                    vm.itemValue = _itemValues;
                                }
                            }
                        }
                    }
                }
            };
            return _data
        };
        $scope.close = function() {
            vm.isSkuShow = false;
        };
        function getPrice(){
            if(!vm.itemValueLine){
                SweetAlert.swal({
                    title: '请选择完整的属性',
                    imageUrl: Theme.sweetHintImg(),
                    text: "",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            var pageCount = vm.article.pageCount ? vm.article.pageCount : vm.page.length;
            var parameter = {
                productId: vm.product.id,
                itemValueId: vm.itemValueLine.itemValueId,
                itemValueLineId: vm.itemValueLine.id,
                quantity:pageCount,
                copy:vm.quantity
            }
            Product.getCostV2(parameter,function(data){
                if (data.status == 200) {
                    vm.amount = data.message.toFixed(2);
                    vm.price = (data.message/vm.quantity).toFixed(2);
                }
            },function(error){
                // SweetAlert.swal({
                //     title: error.data.errorMessage,
                //     imageUrl: Theme.sweetHintImg(),
                //     text: "",
                //     timer: 2000,
                //     showConfirmButton: false
                // });
            })
        };
        $scope.addQuantity = function(){
            vm.quantity = vm.quantity + 1;
            if(vm.itemValueLine){
                getPrice()
            }
        };
        $scope.subtract = function(){
            if(vm.quantity <= vm.itemValue.minimumOrder){
                SweetAlert.swal({
                    title: "购买数量不能小于" + vm.itemValue.minimumOrder,
                    imageUrl: Theme.sweetHintImg(),
                    text: "",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            if(vm.quantity == 1){
                SweetAlert.swal({
                    title: '购买数量不能小于1',
                    imageUrl: Theme.sweetHintImg(),
                    text: "",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            vm.quantity--
            if(vm.itemValueLine){
                getPrice()
            }
        };

        $scope.shoppingSave = function(){
            if(!vm.itemValueLine){
                SweetAlert.swal({
                    title: '请选择完整的属性',
                    imageUrl: Theme.sweetHintImg(),
                    text: "",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            var pageCount = vm.article.pageCount ? vm.article.pageCount : vm.page.length;
            var parameter = {
                productId: vm.product.id,
                itemValueId: vm.itemValueLine.itemValueId,
                itemValueLineId: vm.itemValueLine.id,
                quantity:pageCount,
                copy:vm.quantity
            }
            Product.getCostV2(parameter,function(data){
                if (data.status == 200) {
                    vm.amount = data.message.toFixed(2);
                    vm.price = (data.message/vm.quantity).toFixed(2);
                    Cart.addGoods({
                        articleId: vm.aid,
                        copy: vm.quantity,
                        quantity: vm.article.pageCount,
                        productId: vm.article.productId,
                        itemValueLineId:vm.itemValueLine.id,
                        itemValueId: vm.article.itemValueId,
                        orderWay: vm.store.orderWay,
                        productXmlId: vm.article.productXmlId,
                    }, function (res) {
                        if(res.status == 400){
                            swal({
                                title: "失败",
                                text: res.message || "加入购物车失败",
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                            return
                        }
                        if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {

                            if(localStorage.getItem('distinguish') == 'jdApp'){
                                jd.miniProgram.redirectTo({url: '/pages/shop/shoppingCar/shoppingCar'})
                                return
                            }
                            uni.redirectTo({
                                url: '/pages/shop/shoppingCar/shoppingCar'
                            })
                        } else {
                            window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/cart';
                        }
                    })
                }
            },function(error){
                SweetAlert.swal({
                    title: error.data.errorMessage,
                    imageUrl: Theme.sweetHintImg(),
                    text: "",
                    timer: 2000,
                    showConfirmButton: false
                });
            })

        };
        $scope.purchaseSave = function(){
            if(!vm.itemValueLine){
                SweetAlert.swal({
                    title: '请选择完整的属性',
                    imageUrl: Theme.sweetHintImg(),
                    text: "",
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            $scope.close();
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/modal/protocol-agreement2.html',
                controller: 'OrderProtocolAgreement2Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        var _obj = {
                            orderWay: true
                        }
                        return _obj;
                    }]
                }
            }).result.then(function (data) {
                if (data && data.agree) {
                    var pageCount = vm.article.pageCount ? vm.article.pageCount : vm.page.length;
                    var parameter = {
                        productId: vm.product.id,
                        itemValueId: vm.itemValueLine.itemValueId,
                        itemValueLineId: vm.itemValueLine.id,
                        quantity:pageCount,
                        copy:vm.quantity
                    }
                    Product.getCostV2(parameter,function(data){
                        if (data.status == 200) {
                            vm.amount = data.message.toFixed(2);
                            vm.price = (data.message/vm.quantity).toFixed(2);
                        }
                        if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                            var str = JSON.stringify({
                                articleId:vm.aid,
                                itemValueLineId:vm.itemValueLine.id,
                                productId: vm.article.productId,
                                itemValueId: vm.itemValue.id,
                                copy:vm.quantity
                            });
                            if(localStorage.getItem('distinguish') == 'jdApp'){
                                jd.miniProgram.redirectTo({url: '/pages/shop/placeOrder/placeOrder?jsonStr=' + str})
                                return
                            }
                            uni.redirectTo({
                                url: '/pages/shop/placeOrder/placeOrder?jsonStr=' + str
                            })
                        } else {
                            var arr={
                                ids: vm.aid,
                                quantity: vm.quantity,
                                itemValueLineId: vm.itemValueLine.id,
                                lineSku: vm.itemValueLine.sku,
                                lineSkuNumber: vm.itemValueLine.skuNumber,
                            }
                            window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/sales-order/' + vm.aid + '/1/'+ arr.itemValueLineId +'/'+ vm.itemValue.id +'/'+ arr.quantity +'/'+ vm.article.productId + '//new2';
                        }
                    },function(error){
                        SweetAlert.swal({
                            title: error.data.errorMessage,
                            imageUrl: Theme.sweetHintImg(),
                            text: "",
                            timer: 2000,
                            showConfirmButton: false
                        });
                    })
                }
            })
        }
        $scope.backWorks = function(item){
            if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                if(!item){
                    if(localStorage.getItem('distinguish') == 'jdApp'){
                        jd.miniProgram.redirectTo({url: '/pages/portfolio/workManagement/workManagement?productLineId=' + vm.productLineId + '&salesOrderId=' + vm.salesOrderId})
                        return
                    }
                    uni.redirectTo({
                        url: '/pages/portfolio/workManagement/workManagement?productLineId=' + vm.productLineId + '&salesOrderId=' + vm.salesOrderId
                    })
                }else{
                    var item = {typeId: 0,name: '全部'};
                    var str = JSON.stringify(item);
                    if(localStorage.getItem('distinguish') == 'jdApp'){
                        jd.miniProgram.redirectTo({url: '/pages/information/orderListPage/orderListPage?jsonStr=' + str})
                        return
                    }
                    uni.redirectTo({
                        url: '/pages/information/orderListPage/orderListPage?jsonStr=' + str
                    });
                }
            } else {
                if(!item){
                    window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/' + vm.salesOrderId + '/' + vm.productLineId + '/article2';
                }else{
                    window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/sales-order/All';
                }
            }
        }
        vm.productLine = {};
        function getSalesOrders() {
            if(vm.store.orderWay=='orderThenEditor' && vm.salesOrderId){
                SalesOrder.get({id: vm.salesOrderId},function (res) {
                    res.productLines.forEach(function (productLine) {
                        if(productLine.id == vm.productLineId){
                            vm.productLine = productLine;
                        }
                    })
                })
            }
        }
        //边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.dropShadow = function(imagebox,ratio,ratio1){
            if (imagebox.shadow == null) {
                return
            }
            var width = $scope.imageboxW(imagebox) * ratio;
            var height =$scope.imageboxH(imagebox) * ratio1;
            return dropShadow(imagebox,width,height)
        }
        $scope.editWork = function () {
            $state.go('editorPhotoEdit', {storeId: vm.storeId, aid: vm.article.id, soid: vm.salesOrderId, plid: vm.productLineId}, {reload: true});
        };
    }
})();
