(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('SalesOrderController', SalesOrderController);

    SalesOrderController.$inject = ['$q', '$state', '$scope', 'ParseLinks', '$stateParams', 'MessageService', 'paginationConstants', 'pagingParams', 'SalesOrder', 'Customer',
    'ServiceWxJS', '$localStorage', 'StoreConfig', '$uibModal', 'Editors'];

    function SalesOrderController($q, $state, $scope, ParseLinks, $stateParams, MessageService, paginationConstants, pagingParams, SalesOrder, Customer,
                                  ServiceWxJS, $localStorage, StoreConfig, $uibModal, Editors) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.status = pagingParams.status;
        vm.transition = transition;
        vm.rework = rework;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.jump = jump;
        vm.clear = clear;
        vm.search = search;
        vm.changeType = changeType;
        vm.cancelOrder = cancelOrder;
        vm.wechartPayState = wechartPayState;
        vm.isPageWarnExamine = false;
        vm.storeId = $stateParams.storeId || $state.params.storeId;
        if (!vm.storeId) {
            return;
        }


        $scope.popupShow = function(order){
            if (order == null) {
                return;
            }
            vm.rejectReason = order.rejectReason
            vm.isPageWarnExamine = true;
        }


        // 弹窗关闭
        $scope.popupClose=function(){
             vm.reason =  null;
            vm.isPageWarnExamine = false
        }

        vm.wechartPayShow = false;
        vm.requireUserProtocol = false;
        vm.store = $localStorage.store;
        $scope.domain = $localStorage.domain;
        vm.pageType = vm.status == null ?"all": vm.status;

        $scope.getThirdStatus  = function(status){
            // return "商品已投入生产，卖家拒绝退款";
            if (!status) {
                return;
            }
            switch(status) {
                case "ApplyCancelled":
                    return "已申请退款";
                case "RevertApplyCancelled":
                    return "申请退款取消";
                case "SuccessCancelled":
                    return "卖家同意退款";
                case "RejectedCancelled":
                    return "商品已投入生产，卖家拒绝退款";
                case "AppovedCancelled":
                    return "卖家同意退款";
                default:
                    return "";
            }
        }

        init();

        function init() {
            StoreConfig.byStoreIdAndType({storeId:vm.storeId, type:"USERPROTOCOL"}, function (res) {
                if (res && res.type == "USERPROTOCOL") {
                    vm.requireUserProtocol = res.open || false;
                }
            });
        }


        function changeType(type) {
            vm.pageType = type;
            vm.status = vm.pageType;
            vm.transition();
            // loadAll();
        }
        $scope.allIP = function(order){
            if (order == null || order.productLines == null || order.productLines.length == 0) {
                return false;
            }
            for (var i = order.productLines.length - 1; i >= 0; i--) {
                var line = order.productLines[i];
                if (line.productType != 'IPDerive') {
                    return false;
                }
            }
            return true;
        }


        function rework(order){
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确定',
                msg :'确认撤回生产？'
            }, function() {
                SalesOrder.revertSubmit({
                    id: order.id
                }, function(data) {
                    MessageService.success("撤回订单成功");
                    loadAll();
                }, function() {
                    MessageService.error("撤回订单失败");
                })
            })
        }

        function checkAgreement(order, agreeCallback) {
            var quantity = 0;
            for (var i = 0; i < order.productLines.length; i++) {
                var line = order.productLines[i];
                quantity += (line.copy || 0);
            }
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/modal/protocol-agreement.html',
                controller: 'OrderProtocolAgreementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        var _obj = {
                            number: order.number,
                            quantity: quantity
                        }
                        return _obj;
                    }]
                }
            }).result.then(function (data) {
                if (data && data.agree) {
                    if (agreeCallback) {
                        agreeCallback();
                    }
                }
            });
        }

        $scope.submit = function(order){
            if (order == null || order.productLines == null || order.productLines.length == 0 || order.orderLines == null || order.orderLines.length == 0) {
                MessageService.error("作品数量与订单不匹配，提交失败");
                return;
            }

            for (var i = order.productLines.length - 1; i >= 0; i--) {
                var productLine = order.productLines[i]
                var copy = productLine.copy;
                for (var j = order.orderLines.length - 1; j >= 0; j--) {
                    var orderLine = order.orderLines[j];
                    if (orderLine.productLineId == productLine.id) {
                        copy = copy - orderLine.copy;
                    }
                }
                if (copy!= 0) {
                    MessageService.error("作品数量与订单不匹配，提交失败");
                    return;
                }
            }
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确定',
                msg :'确认提交订单？'
            }, function() {
                if (vm.store.orderWay == 'orderThenEditor' && vm.requireUserProtocol){

                    checkAgreement(order, function() {
                        requestSubmit(order);
                    });
                    return;
                } else {
                    requestSubmit(order);
                }
            })
        }

        function requestSubmit(order) {
            SalesOrder.submit({
                id: order.id
            }, function(data) {
                MessageService.success("提交订单成功");
                loadAll();
            }, function() {
                MessageService.error("提交订单失败");
            })
        }

        //获取主要产品行(非促销商品)
        $scope.getOrderLineCover =function(so){
            if (so.orderLines && so.orderLines.length>0) {
                for (var i = so.orderLines.length - 1; i >= 0; i--) {
                    var line = so.orderLines[i];
                    if (line.orderArticle && line.orderArticle.fileName) {
                        return line.orderArticle.cover;
                    }
                }
                return so.orderLines[0].orderArticle.cover;
            }
            return null;

        }
        $scope.getOrderLineQuantity =function(so){
            if (so.orderLines && so.orderLines.length>0) {
                for (var i = so.orderLines.length - 1; i >= 0; i--) {
                    var line = so.orderLines[i];
                    if (line.orderArticle && line.orderArticle.fileName) {
                        return line.copy;
                    }
                }
                return so.orderLines[0].copy;
            }
            return null;
        }
        $scope.getOrderLineSku =function(so){
            if (so.orderLines && so.orderLines.length>0) {
                for (var i = so.orderLines.length - 1; i >= 0; i--) {
                    var line = so.orderLines[i];
                    if (line.orderArticle && line.orderArticle.fileName) {
                        return line.orderArticle.lineSku;
                    }
                }
                return so.orderLines[0].orderArticle.lineSku;
            }
            return null;
        }
        $scope.getOrderLineName =function(so){
            if (so.orderLines && so.orderLines.length>0) {
                for (var i = so.orderLines.length - 1; i >= 0; i--) {
                    var line = so.orderLines[i];
                    if (line.orderArticle && line.orderArticle.fileName) {
                        return line.orderArticle.name;
                    }
                }
                return so.orderLines[0].orderArticle.name;
            }
            return null;
        }
        $scope.getOrderLinePrice =function(so){
            if (so.orderLines && so.orderLines.length>0) {
                for (var i = so.orderLines.length - 1; i >= 0; i--) {
                    var line = so.orderLines[i];
                    if (line.orderArticle && line.orderArticle.fileName) {
                        return line.price;
                    }
                }
                return so.orderLines[0].orderArticle.price;
            }
            return null;
        }


        $scope.managerArticle =  function(order, lineId){
            if (order == null || lineId == null) {
                return;
            }
            $state.go("sales-order-manager", {id:order.id, plid:lineId, storeId: vm.storeId});
        }

        $scope.createArtcle = function(salesOrder,productLine){
            Editors.navigateSelect(vm.storeId, {
                editorType: productLine.editorType,
                pid: productLine.productId,
                vid: productLine.itemValueId,
                soId: salesOrder.id,
                plId: productLine.id,
                selectState: 'productXmlSelect'
            });
            // $state.go('productXmlSelect', {storeId:vm.storeId, plid:productLine.id, vid:productLine.itemValueId, orderId:vm.salesOrder.id, pid:productLine.productId})
        }

        $scope.getCopy = function(order, lineId){
            var copy = 0;
            if (order == null || lineId == null|| order.orderLines == null || order.orderLines.length == 0) {
                return copy ;
            }
            for (var i = order.orderLines.length - 1; i >= 0; i--) {
                var l = order.orderLines[i];
                if (l.productLineId == lineId) {
                    copy = copy+ l.copy;
                }
            }
            return copy;
        }
        $scope.finish = function(id){
            if (id == null) {
                return;
            };
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确定',
                msg :'确认完成订单？'
            }, function() {
                SalesOrder.complete({
                    id: id
                }, function(data) {
                    loadAll();
                }, function() {
                    MessageService.error("完成订单失败");
                })
            })
        }

        $scope.received = function(id){
            if (id == null) {
                return;
            };
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确定',
                msg :'确认收货？'
            }, function() {
                SalesOrder.received({
                    id: id
                }, function(data) {
                    loadAll();
                }, function() {
                    MessageService.error("确认收货失败");
                })
            })
        }

        function wechartPayState(so) {
            vm.salesOrder = so;
            vm.wechartPayShow = !vm.wechartPayShow;
            if (vm.wechartPayShow) {
                ServiceWxJS.payConfig({
                    id: vm.salesOrder.id,
                    storeId: vm.storeId,
                    totalFee: vm.salesOrder.thirdAmount,
                    type:"web",
                    names:vm.salesOrder.productName
                }, function(result){
                    if (result.status!= 200) {
                        MessageService.error("获取支付信息失败");
                        return;
                    }
                    var data = result.message;
                    console.log(result);
                    $('#qrcode1').html("");
                    // 支付二维码
                    $('#qrcode1').qrcode({
                        render: "canvas",
                        width: 176,
                        height: 176,
                        text: result.message.url
                    });
                }, function(){
                    MessageService.error("获取支付信息失败");
                    return;
                });
            } else {
                loadAll();
            }
        }

        vm.user = Customer.current({});
        $q.all([vm.user.$promise]).then(function(){
            loadAll();
        });

        function loadAll() {
            SalesOrder.getAllNumByStoreAndCustomer({
                id: vm.storeId
            }, function(data) {
                vm.receivedNum = data.object.receivedNum;
                vm.receivingNum = data.object.receivingNum;
                vm.unPaidNum = data.object.unPaidNum;
                vm.allNum = data.object.allNum;
                vm.paidNum = data.object.paidNum;
                vm.submitNum = data.object.submittedNum;
                vm.uncheckedNum = data.object.uncheckedNum;
            })
            vm.status = vm.pageType;
            var items = [];
            if (vm.status == 'All') {
            }else if (vm.status =="Created") {
                 items.push({key: "status",op: "in",value: "Created"});
            }else if (vm.status =="Unchecked") {
                 items.push({key: "status",op: "in",value: "Unchecked"});
            }else if (vm.status =="Producing") {
                items.push({key: "status",op: "in",value: "Producing;Paid;CheckIng;CheckedError;OutFailed;ToBeDelivery"});
            }else if (vm.status =="Receiving") {
                 items.push({key: "status",op: "in",value: "Receiving"});
            }else if (vm.status =="Completed") {
                items.push({key: "status",op: "in",value: "Received;AfterSaling;AfterSaled;Completed"});
            }
            if (pagingParams.search) {
                items.push({key: "number,locations.contact,locations.phone", op: "=", value: pagingParams.search});
                SalesOrder.byCondition({
                    items: items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                SalesOrder.byCondition({
                    items: items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.salesOrders = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.salesOrders,function (order,i) {
                    if(order.status=="Created"){
                        getCountDown(order,new Date(order.createdDate).getTime(),vm.store.orderDelayCancel);
                    }
                    if (order.orderWay == "orderThenEditor") {
                        for (var i = order.productLines.length - 1; i >= 0; i--) {
                            var line = order.productLines[i];
                            $scope.getProductLineQuantity(order,line);
                        }
                    }

                })
            }
            function onError(error) {
                MessageService.error("数据加载出错");
            }
        }

        $scope.getProductLineQuantity = function(order,productLine){
            if (productLine == null) {
                return;
            }
            var orderLinequantity = 0;
            var unCompletedQuantity = 0;
            var completedQuantity = 0;
            if (order.orderLines!= null) {
                for (var i = order.orderLines.length - 1; i >= 0; i--) {
                    var line = order.orderLines[i];
                    if (line.orderArticle && line.productLineId == productLine.id) {
                        orderLinequantity = orderLinequantity + line.copy;
                        if (!line.orderArticle.completed) {
                            unCompletedQuantity= unCompletedQuantity + line.copy;
                        }else{
                            completedQuantity = completedQuantity+ line.copy;
                        }
                    }
                }
            }
            productLine.orderLinequantity = orderLinequantity;
            productLine.unCompletedQuantity = unCompletedQuantity;
            productLine.completedQuantity = completedQuantity;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        $(".navbar").show();
        $(".wrap").show().css({'margin': 'margin: 48px 0 0 130px;'});

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                status: vm.status,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                storeId: vm.storeId
            });
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            console.log(vm.page);
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
            loadAll();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function cancelOrder(salesOrder) {
            if (salesOrder == null) {
                return;
            };
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确定',
                msg :'确认要取消该订单吗？'
            }, function() {
                if (salesOrder.status == 'Created') {
                    SalesOrder.cancel({
                        id: salesOrder.id
                    }, function(data) {
                        loadAll();
                    }, function() {
                        MessageService.error("订单取消失败");
                    })
                 }else if(salesOrder.status == 'Unchecked' || salesOrder.status == 'CheckedError' || salesOrder.status == 'Submitted'){
                    SalesOrder.reject({
                        id: salesOrder.id
                    }, function(data) {
                        loadAll();
                    }, function() {
                        MessageService.error("订单取消失败");
                    })
                 }

            })
        }


        //支付剩余时间倒计时
        /**
         * @param orderDate 单生效时间，后台的Date类型传到前端成了毫秒
         * @param orderDelayCancel
         */
        function getCountDown(order,orderDate,orderDelayCancel) {
            var now = new Date();
            //提交订单的时间+30min就是订单失效时间，后台Date传过来变成了毫秒
            var end = new Date(orderDate + orderDelayCancel * 60 * 1000);
            /*两个时间相减,得到的是毫秒ms,变成秒*/
            var result = Math.floor(end - now) / 1000; //result= 30*60s;
            var interval = setInterval(sub, 1000); //定时器 调度对象
            /*封装减1秒的函数*/
            function sub() {
                if (result <=1 && result>0) {
                    loadAll();
                }
                if (result > 1) {

                    result = result - 1;
                    // 计算秒 ，取余
                    var second = Math.floor(result % 60);
                    if(second<10){
                        second="0"+second;
                    }
                    //计算分 ，换算有多少分，取余，余出多少秒
                    var minite = Math.floor((result / 60) % 60);
                    if(minite<10){
                        minite="0"+minite;
                    }
                    //计算小时
                    var hour = Math.floor(result/1000/60/60%24);
                    if(hour<10){
                        hour="0"+hour;
                    }
                    //计算天
                    // var day = Math.floor(result/1000/60/60/24);
                    // if(day<10){
                    //     day="0"+day;
                    // }
                    order.remainTime = hour+":"+minite + ":" + second + '订单自动取消';
                    $scope.$digest();
                } else {
                    //这里可以添加倒计时结束后需要执行的事件
                    // console.log("未在规定时间内支付，订单已失效！");
                    order.remainTime =0;
                    window.clearInterval(interval);
                    // loadAll();
                    $scope.$digest();
                }
            };
        }
    }
})();
