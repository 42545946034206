
//旋转
var isRotate = false;
function webRotate($scope, $event, item, _top, _left, callback) {
    //开关打开
    isRotate = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isRotate == false) {
            return;
        }
        item.rotation.angle = getAngle(_left,_top, e.clientX, e.clientY);
        item.rotation.angle = adsorbAngle(item.rotation.angle)
        $scope.$apply();
    }
    //鼠标抬起事件
    window.onmouseup = function (e) {
        if(isRotate){
            callback()
        }
        isRotate = false;
    };
}
//pt换算成px
function ptToPx(pt) {
    var px = pt * this.getDPI() / 72;
    return px;
}

function getDPI() {
    var arrDPI = new Array();
    if (window.screen.deviceXDPI != undefined) {
        arrDPI[0] = window.screen.deviceXDPI;
        arrDPI[1] = window.screen.deviceYDPI;
    } else {
        var tmpNode = document.createElement("DIV");
        tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
        document.body.appendChild(tmpNode);
        arrDPI[0] = parseInt(tmpNode.offsetWidth);
        arrDPI[1] = parseInt(tmpNode.offsetHeight);
        tmpNode.parentNode.removeChild(tmpNode);
    }
    return arrDPI[0];
}

/*******************************************素材处理*************************************************************************/
//添加素材
function webAddImageart($scope, $http,item, editbox, callback) {
    $http({url: item.resource.identifier + '?imageInfo'}).success(function (data) {
        var _imageart = {
            movable: true,
            editable: true,
            onlyshow: false,
            offsetx: '0',
            offsety: '0',
            transparency: '1',
            geometry: {
                x: '0',
                y: '0',
                width: data.width,
                height: data.height
            },
            rotation: {
                angle: '0'
            },
            resource: {
                provider: '',
                identifier: item.identifier,
            },
            width:item.width,
            height: item.height,
            imageInfo: data,
            scale: 1,
            useradded: true,
            type: 'dynamic',
            isArt: false,
            current:'imageart'
        }

        var bgImgW = data.width;
        var bgImgH = data.height;
        var bgImgR = bgImgW/bgImgH;
        if(bgImgR >= 1){
            if(bgImgW > 200){
                _imageart.geometry.width = 200;
                _imageart.geometry.height = _imageart.geometry.width * bgImgH / bgImgW;
            }else {
                _imageart.geometry.width = bgImgW;
                _imageart.geometry.height = bgImgH;
            }
        }else {
            if(bgImgH > 200){
                _imageart.geometry.height = 200;
                _imageart.geometry.width = _imageart.geometry.height * bgImgW / bgImgH;
            }else {
                _imageart.geometry.width = bgImgW;
                _imageart.geometry.height = bgImgH;
            }
        }
        _imageart.width = _imageart.geometry.width;
        _imageart.height = _imageart.geometry.height;
        _imageart.geometry.x = (editbox.width - _imageart.geometry.width) / 2 + editbox.x;
        _imageart.geometry.y = (editbox.height - _imageart.geometry.height) / 2 + editbox.y;
        callback(_imageart)
    }).error(function (error) {});
}
// 通用的缩放算法
// $event: 鼠标事件
// item: 用户数据，item.geometry可用
// stick :  left - 左
//          leftTop - 左上
//          top - 上
//          rightTop - 右上
//          right - 右
//          rightBottom - 右下
//          bottom - 下
//          leftBottom - 左下
//
//      leftTop ---------- top ----------- rightTop
//          |                                   |
//          |                                   |
//         left                               right
//          |                                   |
//          |                                   |
//       leftBottom ----- bottom ---------- rightBottom
//
//  boxElement: box框元素，可以用来计算box的位置和尺寸
var boxZoomStickDown = false;
function editorBoxZoom($event, item, stick, scale, boxElement, moveCallback, upCallback) {
    if (!item || !stick || !boxElement) {
        return;
    }
    var element = {};
    if (boxElement.offset) {
        // 元素组
        element = boxElement[0];
    } else if (boxElement.offsetLeft) {
        element = boxElement;
    }
    boxZoomStickDown = true;
    var l0 = element.offsetLeft;
    var t0 = element.offsetTop;
    var w0 = element.offsetWidth;
    var h0 = element.offsetHeight;
    var cx = l0 + w0 / 2;   // 原中心点x
    var cy = t0 + h0 / 2;   // 原中心点y
    var angle = Number(item.rotation.angle);
    var pt0 = rotatePoint({x: $event.clientX, y: $event.clientY}, {x: cx, y: cy}, -angle);
    var dx = 0;     // 框left变化
    var dy = 0;     // 框top变化
    var dw = 0;     // 框长变化
    var dh = 0;     // 框高变化
    var dcx = 0;    // 中心点x向偏移
    var dcy = 0;    // 中心点y向偏移

    var gx = item.geometry.x;
    var gy = item.geometry.y;
    var gw = item.geometry.width;
    var gh = item.geometry.height;
    if(item.offsetx || item.offsety){
        var nw = angular.copy(item.geometry.width);
        var nh = angular.copy(item.geometry.height);
        var nw1 = angular.copy(item.width);
        var nh1 = angular.copy(item.height);
        var x1 = angular.copy(item.offsetx);
        var y1 = angular.copy(item.offsety);
    }

    if(item.style){
        var nw = angular.copy(item.geometry.width);
        var nh = angular.copy(item.geometry.height);
        var _size = angular.copy(parseInt(item.style.size));
        var _lineheight = angular.copy(parseInt(item.style.lineheight));
    }


    function px2mm(pixels) {
        return (pixels || 0) * 25.4 / 96;
    }

    window.onmousemove = function (e) {
        if (!boxZoomStickDown) {
            e.stopPropagation();
            return;
        }
        var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, -angle);
        if (stick == 'left') {
            dx = pt1.x - pt0.x;
            dw = -dx;
            dcx = dx/2;
        }
        else if (stick == 'top') {
            dy = pt1.y - pt0.y;
            dh = -dy;
            dcy = dy/2;
        }
        else if (stick == 'right') {
            dw = pt1.x - pt0.x;
            dcx = dw / 2;
        }
        else if (stick == 'bottom') {
            dh = pt1.y - pt0.y;
            dcy = dh / 2;
        }
        else if (stick == 'leftTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dx = offsetX;
            dy = offsetY;

            dw = -dx;
            dh = -dy;
            dcx = dx / 2;
            dcy = dy / 2;
        }
        else if (stick == 'leftBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dx = offsetX;
            dw = -dx;
            dh = offsetY;
            dcx = dx / 2;
            dcy = dh / 2;
        }
        else if (stick == 'rightTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dy = offsetY;
            dw = offsetX;
            dh = -dy;
            dcx = dw / 2;
            dcy = dy / 2;
        }
        else if (stick == 'rightBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dw = offsetX;
            dh = offsetY;
            dcx = dw / 2;
            dcy = dh / 2;
        }
        else {
            console.error('zoom-dragging - invalid stick: ' + stick);
            e.stopPropagation();
            return;
        }
        // 如果框尺寸小于5mm, 不处理
        if (gw + (dw / scale) < 5 || gh + (dh / scale) < 5) {
            e.stopPropagation();
            return;
        }
        // 中心点偏移dc1
        var dc1 = rotatePoint({x: dcx, y: dcy}, {x: 0, y: 0}, angle);
        // 偏移补偿
        var cx_fix =  dc1.x - dcx;
        var cy_fix = dc1.y - dcy;

        // item.geometry.x = gx + ((dx + cx_fix) / scale);
        // item.geometry.y = gy + ((dy + cy_fix) / scale);
        item.geometry.width = gw + (dw / scale);
        item.geometry.height = gh + (dh / scale);

        if(item.style){
            if(item.geometry.width > ptToPx(item.style.size)){
                item.geometry.x = gx + ((dx + cx_fix) / scale);
                item.geometry.y = gy + ((dy + cy_fix) / scale);
            }
        }else{
            item.geometry.x = gx + ((dx + cx_fix) / scale);
            item.geometry.y = gy + ((dy + cy_fix) / scale);
        }

        if(item.offsetx || item.offsety){
            item.offsetx = ((gw + dw / scale) / (nw / nw1)) / (nw1 / x1);
            item.offsety = ((gh + dh / scale) / (nh / nh1)) / (nh1 / y1);
        }

        if(item.style){
            if((stick != 'left' && stick != 'top' && stick != 'right' && stick != 'bottom')){
                var size1 = Math.ceil((gw + (dw / scale)) / nw * _size);
                if(size1<6){
                    size1 = 6;
                    item.style.size = size1;
                    item.style.lineheight = Math.floor((size1 / _size * _lineheight));
                    boxZoomStickDown = false;
                    if (upCallback) {
                        upCallback(item);
                    }
                    return false
                }
                item.style.size = size1;
                item.style.lineheight = Math.floor((size1 / _size * _lineheight));
            }
            if(stick == 'left'|| stick == 'right'){
                if(item.geometry.width < ptToPx(item.style.size)){
                    item.geometry.width = ptToPx(item.style.size)+10;
                    boxZoomStickDown = false;
                    if (upCallback) {
                        upCallback(item);
                    }
                }
            }
            if(stick == 'top' || stick == 'bottom'){
                if(item.geometry.height < ptToPx(item.style.size)){
                    item.geometry.height = ptToPx(item.style.size)+10;
                    boxZoomStickDown = false;
                    if (upCallback) {
                        upCallback(item);
                    }
                }
            }
        }

        // if(item.style && (stick != 'left' && stick != 'top' && stick != 'right' && stick != 'bottom')){
        //     var size1 = Math.ceil((gw + (dw / scale)) / nw * _size);
        //     if (size1 <= 6) {
        //         size1 = 6
        //     }
        //     item.style.size = size1;
        //     item.style.lineheight = Math.floor((size1 / _size * _lineheight));
        // }
        if (moveCallback) {
            moveCallback(item);
        }
        e.stopPropagation();
    }

    window.onmouseup = function (e) {
        if (!boxZoomStickDown) {
            e.stopPropagation();
            return;
        }
        var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, -angle);
        if (stick == 'left') {
            dx = pt1.x - pt0.x;
            dw = -dx;
            dcx = dx/2;
        }
        else if (stick == 'top') {
            dy = pt1.y - pt0.y;
            dh = -dy;
            dcy = dy/2;
        }
        else if (stick == 'right') {
            dw = pt1.x - pt0.x;
            dcx = dw / 2;
        }
        else if (stick == 'bottom') {
            dh = pt1.y - pt0.y;
            dcy = dh / 2;
        }
        else if (stick == 'leftTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dx = offsetX;
            dy = offsetY;

            dw = -dx;
            dh = -dy;
            dcx = dx / 2;
            dcy = dy / 2;
        }
        else if (stick == 'leftBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dx = offsetX;
            dw = -dx;
            dh = offsetY;
            dcx = dx / 2;
            dcy = dh / 2;
        }
        else if (stick == 'rightTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dy = offsetY;
            dw = offsetX;
            dh = -dy;
            dcx = dw / 2;
            dcy = dy / 2;
        }
        else if (stick == 'rightBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dw = offsetX;
            dh = offsetY;
            dcx = dw / 2;
            dcy = dh / 2;
        }
        else {
            console.error('zoom-dragging - invalid stick: ' + stick);
            e.stopPropagation();
            return;
        }

        // 如果框尺寸小于5mm, 不处理
        if (gw + (dw / scale) < 5 || gh + (dh / scale) < 5) {
            e.stopPropagation();
            return;
        }

        // 中心点偏移dc1
        var dc1 = rotatePoint({x: dcx, y: dcy}, {x: 0, y: 0}, angle);
        // 偏移补偿
        var cx_fix =  dc1.x - dcx;
        var cy_fix = dc1.y - dcy;

        if(!item.style){
            item.geometry.x = gx + ((dx + cx_fix) / scale);
            item.geometry.y = gy + ((dy + cy_fix) / scale);
            item.geometry.width = gw + (dw / scale);
            item.geometry.height = gh + (dh / scale);
        }

        if (upCallback) {
            upCallback(item);
        }
        boxZoomStickDown = false;
        e.stopPropagation();
    }
}
//拖动素材
var isDown = false;
var moveItem = [];
function moveWebdown($scope, $event, item, editbox, ratio, callback, moveCallback) {
    var x = $event.clientX;
    var y = $event.clientY;
    item.l = angular.copy(item.geometry.x * ratio);
    item.t = angular.copy(item.geometry.y * ratio);
    moveItem.push(item)
    //开关打开
    isDown = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isDown == false) {
            return;
        }
        moveItem.forEach(function (item1) {
            var nx = e.clientX;
            var ny = e.clientY;
            //计算移动后的左偏移量和顶部的偏移量
            var nl = nx - (x - item1.l);
            var nt = ny - (y - item1.t);
            // if(item.useradded){
            //     if(nl<editbox.x * ratio){ nl = editbox.x * ratio};
            //     if(nl > r){ nl = r};
            //     if(nt < editbox.y * ratio){ nt = editbox.y * ratio};
            //     if(nt > b){ nt = b};
            // }else {
            //     if(nl<0){ nl = 0};
            //     if(nl > r){ nl = r};
            //     if(nt < 0){ nt = 0};
            //     if(nt > b){ nt = b};
            // }

            item1.geometry.x = nl / ratio;
            item1.geometry.y = nt / ratio;
        })
        if(moveCallback){
            moveCallback()
        }
        $scope.$digest();
    }
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        moveItem = [];
        if(isDown){
            callback()
        }
        isDown = false;
        $event.stopPropagation();
    }
}
//素材缩放
var isImageartZoom = false;
function imageartZoom($scope, $event,item, direction, ratio,editbox, callback) {
    if(item.lock){return}
    var x = $event.clientX;
    var y = $event.clientY;
    //获取w和h
    var nw = angular.copy(item.geometry.width) * ratio;
    var nh = angular.copy(item.geometry.height) * ratio;
    var nw1 = angular.copy(item.width) * ratio;
    var nh1 = angular.copy(item.height) * ratio;
    var x1 = angular.copy(item.offsetx * ratio);
    var y1 = angular.copy(item.offsety * ratio);
    var x2 = item.geometry.x * ratio;
    var y2 = item.geometry.y * ratio;
    var _scale = angular.copy(item.scale);
    var _left = $($event.target).parents('.selectedBorder').offset().left + (nw/2);
    var _top = $($event.target).parents('.selectedBorder').offset().top + (nh/2);
    var x3 = Math.abs(_left - $event.clientX);
    var y3 = Math.abs(_top - $event.clientY);
    var distance = Math.sqrt(Math.pow(x3, 2) + Math.pow(y3, 2));
    //开关打开
    isImageartZoom = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isImageartZoom == false) {
            return;
        }
        var nl = 0;
        var x = Math.abs(_left + nl - e.clientX);
        var y = Math.abs(_top - e.clientY);
        var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        nl = (parseInt(z - distance)) / ratio;
        var _w = (Number(nw) + Number(nl));
        var _h = _w / nw * nh;
        if(_w > 20){
            item.geometry.width = _w / ratio;
            item.geometry.height = _h / ratio;
            item.geometry.x = (x2 - (_w - nw)/2) / ratio;
            item.geometry.y = (y2 - (_h - nh)/2) / ratio;
            item.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / ratio;
            item.offsety = (_h / (nh / nh1)) / (nh1 / y1) / ratio;
        }
        $scope.$digest();
    }
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        if(isImageartZoom){
            callback()
        }
        isImageartZoom = false;
        $event.stopPropagation();
    }
}

//素材旋转
var isImageartRotate = false;
function imageartWebRotate($scope, $event, item, ratio, callback) {
    var _top = $($event.target).parents('.c-xml-page-material').offset().top + (item.geometry.height * ratio / 2);
    var _left = $($event.target).parents('.c-xml-page-material').offset().left + (item.geometry.width * ratio / 2);

    //开关打开
    isImageartRotate = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isImageartRotate == false) {
            return;
        }
        item.rotation.angle = getAngle(_left,_top, e.clientX, e.clientY);
        $scope.$digest();
    }
    //鼠标抬起事件
    window.onmouseup = function (e) {
        if(isImageartRotate){
            callback()
        }
        isImageartRotate = false;
    };
}

/*******************************************文字处理*************************************************************************/
//添加文字
function textboxWebAdd(defaultFont, fontStyles, size, callback) {
    var _font = {
        geometry: {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        },
        rotation: {
            angle: '0'
        },
        text: '双击输入文字',
        style: {
            font: defaultFont.family,
            size: size,
            color: '#000000',
            bold: false,
            italic: false,
            lineheight: size,
            letterspacing: 0,
            align: 'left',
            writingmode:'horizontal-tb'
        },
        fontuuid: defaultFont.uuid,
        identifier: "",
        editable: true,
        movable: true,
        lock: false,
        version: '2.0',
        transparency: '1',
        selected: true,
        loading: true,
        useradded: true
    };
    var _fontStyle = [];
    for(var j=0; j<fontStyles.length; j++){
        _fontStyle.push(fontStyles[j].style)
    }
    if(_fontStyle.indexOf('NORMAL') != '-1'){
        _font.style.bold = false;
        _font.style.italic = false;
    }else {
        if(_fontStyle.indexOf('BOLD_ITALIC') != '-1'){
            _font.style.bold = true;
            _font.style.italic = true;
        }else {
            if(_fontStyle.indexOf('ITALIC') != '-1'){
                _font.style.italic = true;
                _font.style.bold = false;
            }
            if(_fontStyle.indexOf('BOLD') != '-1'){
                _font.style.bold = true;
                _font.style.italic = false;
            }
        }
    }
    callback(_font)
}
var TjTools = {
    canvas: null
}
function getTextWidthInternal(text, fontWeight, fontStyle, fontSize, fontFamily) {
    // re-use canvas object for better performance
    var canvas = TjTools.canvas || (TjTools.canvas = document.createElement('canvas'));
    var context = canvas.getContext('2d');
    context.font = (fontWeight || 'normal') + ' ' +
        (fontSize + 'pt') + ' ' +
        (fontFamily || '') + ' ' + (fontStyle || 'normal');
    var metrics = context.measureText('陈');
    return metrics.height + 6;
}

// ptSrc: 圆上某点(初始点);
// ptRotationCenter: 圆心点;
// angle: 旋转角度° -- [angle * M_PI / 180]:将角度换算为弧度
// 【注意】angle 逆时针为正，顺时针为负
function rotatePoint(ptSrc, ptRotationCenter, angle){
    var a = ptRotationCenter.x;
    var b = ptRotationCenter.y;
    var x0 = ptSrc.x;
    var y0 = ptSrc.y;
    var rx = a + (x0-a) * Math.cos(angle * Math.PI / 180) - (y0-b) * Math.sin(angle * Math.PI / 180);
    var ry = b + (x0-a) * Math.sin(angle * Math.PI / 180) + (y0-b) * Math.cos(angle * Math.PI / 180);
    var json = {x:rx,y:ry}
    return json;
}
function linePoint_by_x(x, pt0, pt1) {
    if (Math.abs(x - pt0.x) < 1) {
        return {x: x, y: pt0.y};
    }
    if (Math.abs(x - pt1.x < 1)) {
        return {x: x, y: pt1.y};
    }
    if (Math.abs(pt1.x - pt0.x) < 1) {
        return {x: x, y: pt1.y};
    }
    else {
        var y = (x - pt0.x) * (pt1.y - pt0.y) / (pt1.x - pt0.x) + pt0.y;
        return {x: x, y: y};
    }
}

function linePoint_by_y(y, pt0, pt1) {
    if (Math.abs(y - pt0.y) < 1) {
        return {x: pt0.x, y: y};
    }
    if (Math.abs(y - pt1.y) < 1) {
        return {x: pt1.x, y: y};
    }
    if (Math.abs(pt1.y - pt0.y) < 1) {
        return {x: pt1.x, y: y};
    }
    var x = (y - pt0.y) * (pt1.x - pt0.x) / (pt1.y - pt0.y) + pt0.x;
    return {x: x, y: y};

}
function linePoint(pt, pt0, pt1) {
    var pt_by_x = linePoint_by_x(pt.x, pt0, pt1);
    var pt_by_y = linePoint_by_y(pt.y, pt0, pt1);
    var distance1 = Math.pow(pt_by_x.x - pt0.x, 2) + Math.pow(pt_by_x.y - pt0.y, 2);
    var distance2 = Math.pow(pt_by_y.x - pt0.x, 2) + Math.pow(pt_by_y.y - pt0.y, 2);
    if (distance1 >= distance2) {
        return pt_by_x;
    } else {
        return pt_by_y;
    }
}

//文字左右按钮
var isTextDown = false;
function textboxWebZoom($event, item, element, ratio, editbox, callback1, callback2){
    if(item.lock){return}
    fn($event)
    var nw = angular.copy(item.geometry.width);
    var nh = angular.copy(item.geometry.height);
    var left = angular.copy(item.geometry.x);
    var top = angular.copy(item.geometry.y);
    var x2 = item.geometry.x;
    var y2 = item.geometry.y;
    var _cx = $($event.target).parents('.selectedBorder').offset().left + (nw*ratio/2);
    var _cy = $($event.target).parents('.selectedBorder').offset().top + (nh*ratio/2);
    var angle = Number(item.rotation.angle);
    var pt0 = rotatePoint({x: $event.clientX, y: $event.clientY}, {x: _cx, y: _cy}, -angle);
    // 鼠标起始点
    var x0 = pt0.x;
    var y0 = pt0.y;

    var dx0 = 1;
    var dy0 = 1;
    if (x0 < _cx) {
        dx0 = _cx - x0;
    } else {
        dx0 = x0 - _cx;
    }
    if (y0 < _cy) {
        dy0 = _cy - y0;
    } else {
        dy0 = y0 - _cy;
    }

    //开关打开
    isTextDown = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isTextDown == false) {
            return;
        }
        var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: _cx, y: _cy}, -angle);
        // 鼠标坐标
        var x1 = pt1.x;
        var y1 = pt1.y;

        var dx = 0;
        var dy = 0;
        if (x0 < _cx) {
            dx = _cx - x1;
        } else {
            dx = x1 - _cx;
        }
        if (y0 < _cy) {
            dy = _cy - y1;
        } else {
            dy = y1 - _cy;
        }
        var _w ;
        if (dx0 > 1) {
            _w = (Number(nw) + Number(nw * (dx - dx0)/dx0));
        } else {
            _w = nw;
        }
        var _h;
        if (dy0 > 1) {
            _h = (Number(nh) + Number(nh * (dy - dy0)/dy0));
        } else {
            _h = nh;
        }

        if (_w < 13.175) {
            _w = 13.175;
        }
        if (_h < 13.175) {
            _h = 13.175;
        }

        if (element == 'left' || element == 'right') {
            item.geometry.width = _w;
            item.geometry.x = (x2 - (_w - nw)/2);
        }else{
            item.geometry.height = _h;
            item.geometry.y = (y2 - (_h - nh)/2);
        }
        callback1(item)
        $event.stopPropagation();
    }
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        if(isTextDown){
            callback2(item)
        }
        isTextDown = false;
        $event.stopPropagation();
    }
};
function textboxWebZoom1($event, item, element, ratio, callback1, callback2){
    if(item.lock){return}
    fn($event)

    //获取w和h
    var nw = angular.copy(item.geometry.width);
    var nh = angular.copy(item.geometry.height);

    var _size = angular.copy(parseInt(item.style.size));
    var _lineheight = angular.copy(parseInt(item.style.lineheight));
    // box起始点(mm)
    var x2 = item.geometry.x;
    var y2 = item.geometry.y;
    // 中心点(屏幕缩放)
    var target = $($event.target).parents('.selectedBorder');
    var _cx = target.offset().left + target.width() / 2;
    var _cy = target.offset().top + target.height() / 2;
    var angle = (Number(item.rotation.angle) + 360) % 360;
    var pt0 = rotatePoint({x: $event.clientX, y: $event.clientY}, {x: _cx, y: _cy}, -angle);

    // console.log("pt0", pt0, "c", {x: _cx, y: _cy}, angle);

    // 鼠标起始点
    var x0 = pt0.x;
    var y0 = pt0.y;

    var dx0 = 1;
    var dy0 = 1;
    switch (element) {
        case 'left': {// left-top
            dx0 = _cx - x0;
            dy0 = _cy - y0;
            break;
        }
        case "top": { // right-top
            dx0 = x0 - _cx;
            dy0 = _cy - y0;
            break;
        }
        case 'right':  { // right-bottom
            dx0 = x0 - _cx;
            dy0 = y0 - _cy;
            break;
        }
        case 'bottom':  {// left-bottom
            dx0 = _cx - x0;
            dy0 = y0 - _cy;
            break;
        }
        default:
            return;
    }
    var delta0 = Math.sqrt(dx0 * dx0 + dy0 * dy0);
    // if (x0 < _cx) {
    //     dx0 = _cx - x0;
    // } else {
    //     dx0 = x0 - _cx;
    // }
    // if (y0 < _cy) {
    //     dy0 = _cy - y0;
    // } else {
    //     dy0 = y0 - _cy;
    // }

    //开关打开
    isTextDown = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isTextDown == false) {return;}
        // 鼠标坐标
        var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: _cx, y: _cy}, -angle);
        // pt1 = linePoint(pt11, {x: _cx, y: _cy}, pt0);
        // console.log("pt11", pt11, "l", pt1, "pt0", {x: _cx, y: _cy}, pt0, angle);
        var x1 = pt1.x;
        var y1 = pt1.y;

        var dx = 0;
        var dy = 0;
        switch (element) {
            case 'left': {// left-top
                dx = _cx - x1;
                dy = _cy - y1;
                break;
            }
            case "top": { // right-top
                dx = x1 - _cx;
                dy = _cy - y1;
                break;
            }
            case 'right':  { // right-bottom
                dx = x1 - _cx;
                dy = y1 - _cy;
                break;
            }
            case 'bottom':  {// left-bottom
                dx = _cx - x1;
                dy = y1 - _cy;
                break;
            }
            default:
                return;
        }
        if (dx*dx0 < 0 || dy*dy0 < 0) {
            return;
        }
        var delta = Math.sqrt(dx * dx + dy * dy);

        // if (x0 < _cx) {
        //     if (x1 > _cx) return;
        //     dx = _cx - x1;
        // } else {
        //     if (x1 < _cx) return;
        //     dx = x1 - _cx;
        // }
        // if (y0 < _cy) {
        //     if (y1 > _cy) return;
        //     dy = _cy - y1;
        // } else {
        //     if (y1 < _cy) return;
        //     dy = y1 - _cy;
        // }
        var _w ;
        if (Math.abs(delta0) > 1) {
            _w = Number(nw * delta / delta0);
        } else {
            _w = nw;
        }
        var _h;
        if (Math.abs(delta0) > 1) {
            _h = Number(nh * delta / delta0);
        } else {
            _h = nh;
        }

        var size1 = Math.ceil(_w / nw * _size);
        if (_w < 13.175) {
            _w = 13.175;
        }
        if (_h < 13.175) {
            _h = 13.175;
        }
        if (size1 < 9) {
            size1 = 9;
        }

        // console.log(element, x0, y0, x1, y1, _cx, _cy, angle, nw, nh, _w, _h, _size, size1);

        item.geometry.width = _w;
        item.geometry.height = _h;
        item.geometry.x = (x2 - (_w - nw)/2);
        item.geometry.y = (y2 - (_h - nh)/2);
        item.style.size = size1;
        item.style.lineheight = Math.floor((size1 / _size * _lineheight));
        callback1(item);
        $event.stopPropagation();
    }
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        if(isTextDown){
            callback2(item)
        }
        isTextDown = false;
        $event.stopPropagation();
    }
}

function writingmodeInit(item) {
    if(item == 'vertical-lr' || item == 'vertical-rl'){
        return true
    }else{
        return false
    }
}
//文字旋转
var isTextRotate = false;
function textboxWebRotate($scope, $event, item, ratio, callback) {
    var target = $($event.target).parents('.c-xml-page-textboxe');
    var _top = target.offset().top + (target.height() / 2);
    var _left = target.offset().left + (target.width() / 2);
    //开关打开
    isTextRotate = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isTextRotate == false) {
            return;
        }
        item.rotation.angle = getAngle(_left,_top, e.clientX, e.clientY);
        $scope.$digest();
    }
    //鼠标抬起事件
    window.onmouseup = function (e) {
        if(isTextRotate){
            item.rotation.angle = getAngle(_left,_top, e.clientX, e.clientY);
            callback();
        }
        isTextRotate = false;
    };
}
/*******************************************文字模板处理*************************************************************************/
function textDocWebAdd($scope, $http, level, textdocument, editbox, mediabox, callback, callback1) {
    level.textdocument = {
        background: textdocument.background,
        geometry: {
            width: transitionPx(textdocument.geometry.width),
            height: transitionPx(textdocument.geometry.height),
            x: transitionPx(textdocument.geometry.x),
            y: transitionPx(textdocument.geometry.y)
        },
        rotation: {
            angle:-textdocument.rotation.angle||0
        },
        current: 'textdocument',
        levels: angular.copy(textdocument.levels),
        lock: false,
        transparency: textdocument.transparency,
        movable: textdocument.movable,
        useradded:true,
        editable: textdocument.editable,
        scale:textdocument.geometry.width/mediabox.width,
    }
    if (level.textdocument.background && level.textdocument.background.resource &&level.textdocument.background.resource.identifier) {
        getBackgroundSource($http, level.textdocument.background.resource, level.textdocument.geometry.width, level.textdocument.geometry.height, level.textdocument.background, $scope);
        level.textdocument.background.offsetx = transitionPx(level.textdocument.background.offsetx);
        level.textdocument.background.offsety = transitionPx(level.textdocument.background.offsety);
        level.textdocument.background.type = 'Pic'
    }

    angular.forEach(level.textdocument.levels.level, function (lev1, l1) {
        (function (lev,l) {
            if (lev.imageart && lev.imageart.geometry) {
                lev.imageart.offsetx = transitionPx(lev.imageart.offsetx);
                lev.imageart.offsety = transitionPx(lev.imageart.offsety);
                lev.imageart.geometry = {
                    width: transitionPx(lev.imageart.geometry.width),
                    height: transitionPx(lev.imageart.geometry.height),
                    x: transitionPx(lev.imageart.geometry.x),
                    y: transitionPx(lev.imageart.geometry.y)
                };
                lev.imageart.rotation.angle = -lev.imageart.rotation.angle;
                if (lev.imageart.resource.identifier) {
                    getBackgroundSource($http, lev.imageart.resource, lev.imageart.geometry.width, lev.imageart.geometry.height, lev.imageart, $scope);
                }
            }
            if (lev.textbox && lev.textbox.geometry) {
                var text = lev.textbox;
                lev.textbox.geometry = {
                    width: transitionPx(lev.textbox.geometry.width),
                    height: transitionPx(lev.textbox.geometry.height),
                    x: transitionPx(lev.textbox.geometry.x),
                    y: transitionPx(lev.textbox.geometry.y)
                };
                lev.textbox.rotation.angle = -lev.textbox.rotation.angle;
            }
        })(lev1,l1)
    })
    level.textdocument.geometry.x = (editbox.width - level.textdocument.geometry.width) / 2 + editbox.x;
    level.textdocument.geometry.y = (editbox.height - level.textdocument.geometry.height) / 2 + editbox.y;
    callback(level)
}

//保存doments转换MM
function documentWebMM(template,domain) {
    var _template = angular.copy(template);
    _template.textRestLen = 0;
    if(!_template.galleries){
        _template.galleries = {
            gallery:[]
        };
    }else{
        _template.galleries = {
            gallery:[]
        };
    }
    angular.forEach(_template.pages.page, function (page, index) {
        page.recommendLayout = null;
        if (page.editbox == null) {
            page.editbox = page.trimbox;
        }
        page.editbox = {
            width: transitionMm(page.editbox.width),
            height: transitionMm(page.editbox.height),
            x: transitionMm(page.editbox.x),
            y: transitionMm(page.editbox.y)
        };
        if(page.editboxes && page.editboxes.editbox){
            page.editboxes.editbox.forEach(function (editbox, editboxIndex) {
                page.editboxes.editbox[editboxIndex] = {
                    width: transitionMm(editbox.width),
                    height: transitionMm(editbox.height),
                    x: transitionMm(editbox.x),
                    y: transitionMm(editbox.y)
                }
            })
        }
        page.mediabox = {
            width: transitionMm(page.mediabox.width),
            height: transitionMm(page.mediabox.height),
            x: transitionMm(page.mediabox.x),
            y: transitionMm(page.mediabox.y)
        };
        page.trimbox = {
            width: transitionMm(page.trimbox.width),
            height: transitionMm(page.trimbox.height),
            x: transitionMm(page.trimbox.x),
            y: transitionMm(page.trimbox.y)
        };
        page.custom = {};
        if (page.sceneCopy) {
            page.scene = angular.copy(page.sceneCopy);
        }
        if(page.scene){
            page.scene.geometry = {
                width: transitionMm(page.scene.geometry.width),
                height: transitionMm(page.scene.geometry.height),
                x: transitionMm(page.scene.geometry.x),
                y: transitionMm(page.scene.geometry.y)
            };
            page.scene.imagebox.geometry = {
                width: transitionMm(page.scene.imagebox.geometry.width),
                height: transitionMm(page.scene.imagebox.geometry.height),
                x: transitionMm(page.scene.imagebox.geometry.x),
                y: transitionMm(page.scene.imagebox.geometry.y)
            };
        }
        //条形码
        if(page.barcode){
            page.barcode.geometry = {
                width: transitionMm(page.barcode.geometry.width),
                height: transitionMm(page.barcode.geometry.height),
                x: transitionMm(page.barcode.geometry.x),
                y: transitionMm(page.barcode.geometry.y)
            };
        }
        if(page.qrcode){
            page.qrcode.geometry = {
                width: transitionMm(page.qrcode.geometry.width),
                height: transitionMm(page.qrcode.geometry.height),
                x: transitionMm(page.qrcode.geometry.x),
                y: transitionMm(page.qrcode.geometry.y)
            };
        }

        //背景
        if(page.background && page.background.resource && page.background.resource.identifier){
            page.background.offsetx = transitionMm(page.background.offsetx);
            page.background.offsety = transitionMm(page.background.offsety);
        }
        angular.forEach(page.levels.level, function (level, i) {
            if(level.imageart){
                level.imageart.rotation.angle = -level.imageart.rotation.angle;
                level.imageart.offsetx = transitionMm(level.imageart.offsetx);
                level.imageart.offsety = transitionMm(level.imageart.offsety);
                level.imageart.geometry = {
                    width: transitionMm(level.imageart.geometry.width),
                    height: transitionMm(level.imageart.geometry.height),
                    x: transitionMm(level.imageart.geometry.x),
                    y: transitionMm(level.imageart.geometry.y)
                };
                if(level.imageart.resource.identifier.indexOf('http') < 0){
                    level.imageart.resource.identifier = domain + level.imageart.resource.identifier
                }
            }
            if(level.imagebox){
                level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                level.imagebox.geometry = {
                    width: transitionMm(level.imagebox.geometry.width),
                    height: transitionMm(level.imagebox.geometry.height),
                    x: transitionMm(level.imagebox.geometry.x),
                    y: transitionMm(level.imagebox.geometry.y)
                };
                if(level.imagebox.blurredrectangle){
                    level.imagebox.blurredrectangle = {
                        width: transitionMm(level.imagebox.blurredrectangle.width),
                        height: transitionMm(level.imagebox.blurredrectangle.height),
                        x: transitionMm(level.imagebox.blurredrectangle.x),
                        y: transitionMm(level.imagebox.blurredrectangle.y)
                    };
                }
                if(level.imagebox.image){
                    level.imagebox.image.width = transitionMm(level.imagebox.image.width);
                    level.imagebox.image.height = transitionMm(level.imagebox.image.height);
                    level.imagebox.image.offsetx = transitionMm(level.imagebox.image.offsetx);
                    level.imagebox.image.offsety = transitionMm(level.imagebox.image.offsety);
                }
            }
            if(level.textbox&&level.textbox.geometry){
                if (!(level.textbox.text == '' || level.textbox.text == null || level.textbox.text == '双击输入文字' || level.textbox.text == '请输入文字') && (level.textbox.geometry.width != 0 && level.textbox.geometry.height != 0)) {
                    level.textbox.rotation.angle = -level.textbox.rotation.angle;
                    var maxLen = level.textbox.geometry.height/level.textbox.style.size*level.textbox.geometry.width;
                    if(level.textbox.geometry.width==0||level.textbox.geometry.height==0||level.textbox.text.length*level.textbox.style.size>maxLen){
                        level.textbox.reSet = true;
                        _template.textRest = true;
                        _template.textRestLen++;
                    }
                    level.textbox.geometry = {
                        width: transitionMm(level.textbox.geometry.width),
                        height: transitionMm(level.textbox.geometry.height),
                        x: transitionMm(level.textbox.geometry.x),
                        y: transitionMm(level.textbox.geometry.y)
                    };
                }else{
                    level.textbox = null;
                }
            }
            if (level.textdocument && level.textdocument.geometry) {
                level.textdocument.rotation.angle = -level.textdocument.rotation.angle;
                level.textdocument.geometry = {
                    width: transitionMm(level.textdocument.geometry.width),
                    height: transitionMm(level.textdocument.geometry.height),
                    x: transitionMm(level.textdocument.geometry.x),
                    y: transitionMm(level.textdocument.geometry.y)
                };

                var backg = level.textdocument.background;
                if(backg.resource.identifier){
                    backg.offsetx = transitionMm(backg.offsetx);
                    backg.offsety = transitionMm(backg.offsety);
                }

                angular.forEach(level.textdocument.levels.level, function (lev, l) {
                    if (lev.imageart && lev.imageart.geometry) {
                        var art = lev.imageart;
                        art.geometry={
                            width: transitionMm(art.geometry.width),
                            height: transitionMm(art.geometry.height),
                            x: transitionMm(art.geometry.x),
                            y: transitionMm(art.geometry.y)
                        };
                        art.offsetx= transitionMm(art.offsetx);
                        art.offsety= transitionMm(art.offsety);
                    }
                    if (lev.textbox && lev.textbox.geometry) {
                        var text = lev.textbox;
                        text.geometry={
                            width: transitionMm(text.geometry.width),
                            height: transitionMm(text.geometry.height),
                            x: transitionMm(text.geometry.x),
                            y: transitionMm(text.geometry.y)
                        };
                    }
                })
            }
            if (level.calTitleBox && level.calTitleBox.geometry) {
                var _calTitleBox = angular.copy(level.calTitleBox);
                level.calTitleBox.geometry = {
                    width: transitionMm(level.calTitleBox.geometry.width),
                    height: transitionMm(level.calTitleBox.geometry.height),
                    x: transitionMm(level.calTitleBox.geometry.x),
                    y: transitionMm(level.calTitleBox.geometry.y)
                };
                level.calTitleBox.rotation.angle = -level.calTitleBox.rotation.angle;
                level.calTitleBox.settings={
                    border:level.calTitleBox.settings.border,
                    items:{
                        item:angular.copy(_calTitleBox.settings.items.item?_calTitleBox.settings.items.item:_calTitleBox.settings.items)
                    }
                };
                level.calTitleBox.layout={
                    geometry: {
                        width:level.calTitleBox.layout.width,
                        height:level.calTitleBox.layout.height,
                        x:0,
                        y:0,
                    },
                    previewUrl:level.calTitleBox.layout.previewUrl,
                    type:level.calTitleBox.layout.type,
                    items:{
                        item:angular.copy(_calTitleBox.layout.items.item?_calTitleBox.layout.items.item:_calTitleBox.layout.items),
                    }
                };
                level.calTitleBox.layout.items.item.forEach(function (item) {
                    item.geometry = {
                        width:item.w,
                        height:item.h,
                        x:item.x,
                        y:item.y,
                    }
                })
            }
            if (level.calMonthBox && level.calMonthBox.geometry) {
                var _calMonthBox = angular.copy(level.calMonthBox);
                level.calMonthBox.geometry = {
                    width: transitionMm(level.calMonthBox.geometry.width),
                    height: transitionMm(level.calMonthBox.geometry.height),
                    x: transitionMm(level.calMonthBox.geometry.x),
                    y: transitionMm(level.calMonthBox.geometry.y)
                };
                level.calMonthBox.rotation.angle = -level.calMonthBox.rotation.angle;

                level.calMonthBox.weekStyles={item:angular.copy(_calMonthBox.weekStyles.item?_calMonthBox.weekStyles.item:_calMonthBox.weekStyles)};
                level.calMonthBox.weekLayout={
                    geometry: {
                        width:level.calMonthBox.weekLayout.width,
                        height:level.calMonthBox.weekLayout.height,
                        x:0,
                        y:0,
                    },
                    items:{
                        item:angular.copy(_calMonthBox.weekLayout.items.item?_calMonthBox.weekLayout.items.item:_calMonthBox.weekLayout.items)
                    },
                    type:level.calMonthBox.weekLayout.type,
                    previewUrl:level.calMonthBox.weekLayout.previewUrl,
                };
                level.calMonthBox.weekLayout.items.item.forEach(function (weekLayout) {
                    weekLayout.geometry = {
                        width:weekLayout.w,
                        height:weekLayout.h,
                        x:weekLayout.x,
                        y:weekLayout.y,
                    }
                })
                level.calMonthBox.dateStyles={item:angular.copy(_calMonthBox.dateStyles.item?_calMonthBox.dateStyles.item:_calMonthBox.dateStyles)},
                    level.calMonthBox.dateLayout={
                        geometry: {
                            width:level.calMonthBox.dateLayout.width,
                            height:level.calMonthBox.dateLayout.height,
                            x:0,
                            y:0,
                        },
                        items:{
                            item:angular.copy(_calMonthBox.dateLayout.items.item?_calMonthBox.dateLayout.items.item:_calMonthBox.dateLayout.items)
                        },
                        type:level.calMonthBox.dateLayout.type,
                        previewUrl:level.calMonthBox.dateLayout.previewUrl
                    };
                level.calMonthBox.dateLayout.items.item.forEach(function (dateLayout) {
                    dateLayout.geometry = {
                        width:dateLayout.w,
                        height:dateLayout.h,
                        x:dateLayout.x,
                        y:dateLayout.y,
                    }
                })
                level.calMonthBox.weekStyles.item.forEach(function (weekStyle) {
                    var _items = angular.copy(weekStyle.items.item?weekStyle.items.item:weekStyle.items);
                    _items.forEach(function (_item){
                        _item.geometry = {
                            width:_item.w,
                            height:_item.h,
                            x:_item.x,
                            y:_item.y,
                        }
                    })
                    weekStyle.items = {
                        item:_items
                    }
                })
                level.calMonthBox.dateStyles.item.forEach(function (dateStyle) {
                    var _items = angular.copy(dateStyle.items.item?dateStyle.items.item:dateStyle.items);
                    _items.forEach(function (_item){
                        _item.geometry = {
                            width:_item.w,
                            height:_item.h,
                            x:_item.x,
                            y:_item.y,
                        }
                    })
                    dateStyle.items = {
                        item:_items
                    }
                })
            }
        })
    })
    _template.version = '4.0';
    return _template;
}
function imageBoxImg(_template) {
    var isPass = {
        pass: true,
        pageIndex: null,
        levelIndex: null
    };
    angular.forEach(_template.pages.page, function (page, index) {
        if(isPass.pass){
            angular.forEach(page.levels.level, function (level, i) {
                if (level.imagebox&&level.imagebox.image&&level.imagebox.image.resource && isPass.pass) {
                    if(level.imagebox.bokehtype == 'None'){
                        if(level.imagebox.image.offsetx > 0 || level.imagebox.image.offsety > 0 || (level.imagebox.image.offsetx + level.imagebox.image.width)*level.imagebox.image.scale < level.imagebox.geometry.width || (level.imagebox.image.offsety + level.imagebox.image.height)*level.imagebox.image.scale < level.imagebox.geometry.height){
                            isPass.pass = false;
                            isPass.pageIndex = index + 1;
                            isPass.levelIndex = i + 1;
                            console.log(isPass)
                            return false
                        }
                    }
                }
            })
        }
    })
    console.log(isPass)
    return isPass
}
//排序
function sortItem(item) {
    return function(object1, object2) {
        var value1 = object1[item];
        var value2 = object2[item];
        return value1 - value2;
    }
}


var isBoxDown = false;
function boxWebdown1($scope, $event, item, currentPage, ratio,dragBox,CtrlKeyArray, callback) {
    var x = $event.clientX;
    var y = $event.clientY;
    var l = item.geometry.x * ratio;
    var t = item.geometry.y * ratio;
    var r = (currentPage.editbox.width - item.geometry.width + currentPage.editbox.x) * ratio;
    var b = (currentPage.editbox.height - item.geometry.height + currentPage.editbox.y) * ratio;
    if (item.rotation.angle >= 360) {
        item.rotation.angle %= 360;
    }
    //开关打开
    isBoxDown = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (e.target.nodeName == 'SPAN') {
            return;
        }
        if (isBoxDown) {

            //获取x和y
            var nx = e.clientX;
            var ny = e.clientY;
            //计算移动后的左偏移量和顶部的偏移量
            var nl = nx - (x - l);
            var nt = ny - (y - t);
            var imgWidth = item.geometry.width * ratio;
            var imgHeight = item.geometry.height * ratio;
            var x1 = $scope.currentPage.editbox.x * ratio;
            var y1 = $scope.currentPage.editbox.y * ratio;

            $scope.isMoveOut = false;

            if (nl <= x1 || nt <= y1 || nt >= (dragBox.slideH - imgHeight - y1) || nl >= dragBox.slideW - imgWidth - x1) {
                $scope.isMoveOut = true;
            }

            if (nl <= 0) {nl = 0;}
            if (nl >= dragBox.slideW - imgWidth) {nl = dragBox.slideW - imgWidth;}

            if (nt <= 0) {nt = 0;}
            if (nt >= (dragBox.slideH - imgHeight)) {nt = dragBox.slideH - imgHeight;}

            if (dragBox.ctrlKey) {
                moveTogether($scope.currentPage, nx-x, ny-y, dragBox, ratio, CtrlKeyArray,$scope);
            } else {
                item.geometry.x = nl / ratio;
                item.geometry.y = nt / ratio;
                $scope.$digest();
            }

        }

    }
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        if (dragBox.ctrlKey) {
            // moveTogetherEnd(CtrlKeyArray,$scope)
        }
        if(isBoxDown){
            callback()
        }
        isBoxDown = false;
        $event.stopPropagation();
    }
}

function moveTogether(currentPage, clientX, clientY,dragbox,pro,CtrlKeyArray,$scope) {
    // console.log(CtrlKeyArray)
    // console.log(pro)
    // console.log(currentPage)
    // console.log(clientX)
    // console.log(clientY)
    // console.log(dragbox)
    // console.log("--------------")
    var imgWidth = 0, imgHeight = 0, x = 0, y = 0;
    angular.forEach(CtrlKeyArray.imageboxes, function (imagebox, i) {
        // 当前页所有占位框
        var box = currentPage.levels.level[imagebox.levelI].imagebox;
        // console.log(box)
        boxSetting(box, imagebox);
    })

    angular.forEach(CtrlKeyArray.imagearts, function (imageart, i) {
        // 当前页所有素材
        var box = currentPage.levels.level[imageart.levelI].imageart;
        boxSetting(box, imageart);
    })

    angular.forEach(CtrlKeyArray.textboxes, function (textbox, i) {
        // 当前页所有文字
        var box = currentPage.levels.level[textbox.levelI].textbox;
        boxSetting(box, textbox);
    })

    angular.forEach(CtrlKeyArray.textdocuments, function (textdocument, i) {
        // 当前页所有文字
        var box = currentPage.levels.level[textdocument.levelI].textdocument;
        boxSetting(box, textdocument);
    })

    function boxSetting(box, CtrlKeyBox) {
        imgWidth = box.geometry.width * pro;
        imgHeight = box.geometry.height * pro;
        x = CtrlKeyBox.geometry.x * pro + clientX;
        y = CtrlKeyBox.geometry.y * pro + clientY;
        if (x <= 0) {
            x = 0;
        }
        if (x >= (dragbox.slideW - imgWidth)) {
            x = dragbox.slideW - imgWidth;
        }
        if (y <= 0) {
            y = 0;
        }

        if (y >= (dragbox.slideH - imgHeight)) {
            y = dragbox.slideH - imgHeight;
        }
        box.geometry.x = x / pro
        box.geometry.y = y / pro
        console.log("x: "+x+" y: "+y)
        $scope.$digest()
    }
}
function moveTogether1(currentPage, clientX, clientY,vm,$scope) {
    var page = $scope.document.pages[$scope.pageI];
    var imgWidth = 0, imgHeight = 0, x = 0, y = 0, pro = currentPage.pageScaleW * page.scale;
    angular.forEach(vm.CtrlKeyArray.imageboxes, function (imagebox, i) {
        // 当前页所有占位框
        var box = currentPage.levels.level[imagebox.levelI].imagebox;
        boxSetting(box, imagebox);
    })

    angular.forEach(vm.CtrlKeyArray.imagearts, function (imageart, i) {
        // 当前页所有素材
        var box = currentPage.levels.level[imageart.levelI].imageart;
        boxSetting(box, imageart);
    })

    angular.forEach(vm.CtrlKeyArray.textboxes, function (textbox, i) {
        // 当前页所有文字
        var box = currentPage.levels.level[textbox.levelI].textbox;
        boxSetting(box, textbox);
    })

    angular.forEach(vm.CtrlKeyArray.textdocuments, function (textdocument, i) {
        // 当前页所有文字
        var box = currentPage.levels.level[textdocument.levelI].textdocument;
        boxSetting(box, textdocument);
    })

    function boxSetting(box, CtrlKeyBox) {
        imgWidth = box.geometry.width * pro;
        imgHeight = box.geometry.height * pro;
        x = CtrlKeyBox.geometry.x * pro + clientX;
        y = CtrlKeyBox.geometry.y * pro + clientY;
        if (x <= 0) {
            x = 0;
        }
        if (x >= (vm.slideW - imgWidth)) {
            x = vm.slideW - imgWidth;
        }
        if (y <= 0) {
            y = 0;
        }

        if (y >= (vm.slideH - imgHeight)) {
            y = vm.slideH - imgHeight;
        }
        box.geometry.x = x / pro
        box.geometry.y = y / pro
    }
}

function moveTogetherEnd(CtrlKeyArray,$scope) {
    angular.forEach(CtrlKeyArray.imageboxes, function (imagebox, i) {
        // 当前页所有占位框
        var box = $scope.currentPage.levels.level[imagebox.levelI].imagebox;
        ctrlKeySetting(box, imagebox);
    })

    angular.forEach(CtrlKeyArray.imagearts, function (imageart, i) {
        // 当前页所有素材
        var box = $scope.currentPage.levels.level[imageart.levelI].imageart;
        ctrlKeySetting(box, imageart);
    })

    angular.forEach(CtrlKeyArray.textboxes, function (textbox, i) {
        // 当前页所有文字
        var box = $scope.currentPage.levels.level[textbox.levelI].textbox;
        ctrlKeySetting(box, textbox);
    })

    angular.forEach(CtrlKeyArray.textdocuments, function (textdocument, i) {
        // 当前页所有文字
        var box = $scope.currentPage.levels.level[textdocument.levelI].textdocument;
        ctrlKeySetting(box, textdocument);
    })

    function ctrlKeySetting(box, CtrlKeyBox) {
        CtrlKeyBox.geometry.x = box.geometry.x;
        CtrlKeyBox.geometry.y = box.geometry.y;
    }
}

function getAllCtrlKeyArray(vm) {
    var imageboxes = vm.CtrlKeyArray.imageboxes,
        textboxes = vm.CtrlKeyArray.textboxes,
        imagearts = vm.CtrlKeyArray.imagearts,
        all = [];
    var barcode = vm.CtrlKeyArray.barcode;
    var qrcode = vm.CtrlKeyArray.qrcode;
    //选中的占位框
    for (var i = 0; i < imageboxes.length; i++) {
        if (imageboxes[i]) {
            all.push(imageboxes[i]);
        }
    }
    //选中的文本框
    for (var i = 0; i < textboxes.length; i++) {
        if (textboxes[i]) {
            all.push(textboxes[i]);
        }
    }
    //选中的素材
    for (var i = 0; i < imagearts.length; i++) {
        if (imagearts[i]) {
            all.push(imagearts[i]);
        }
    }
    //选中的条码
    for (var i = 0; i < barcode.length; i++) {
        all.push(barcode[i]);
    }
    //选中的条码
    for (var i = 0; i < qrcode.length; i++) {
        all.push(qrcode[i]);
    }
    return all;
}

//移动画布
var isMoveCanvas = false;
function moveCanvas($event, callback) {
    var x = $event.clientX;
    var y = $event.clientY;
    isMoveCanvas = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (!isMoveCanvas) {
            return;
        }
        callback(e.clientX - x, e.clientY - y)
    };
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        isMoveCanvas = false;
    }
}

// 获取操作系统信息
function getOsInfo() {
    var userAgent = navigator.userAgent.toLowerCase();
    var name = 'Unknown';
    var version = 'Unknown';
    if (userAgent.indexOf('win') > -1) {
        name = 'Windows';
        if (userAgent.indexOf('windows nt 5.0') > -1) {
            version = 'Windows 2000';
        } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
            version = 'Windows XP';
        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
            version = 'Windows Vista';
        } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
            version = 'Windows 7';
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
            version = 'Windows 8';
        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
            version = 'Windows 8.1';
        } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
            version = 'Windows 10';
        } else {
            version = 'Unknown';
        }
    } else if (userAgent.indexOf('iphone') > -1) {
        name = 'Iphone';
    } else if (userAgent.indexOf('mac') > -1) {
        name = 'Mac';
    } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {
        name = 'Unix';
    } else if (userAgent.indexOf('linux') > -1) {
        if (userAgent.indexOf('android') > -1) {
            name = 'Android';
        } else {
            name = 'Linux';
        }
    } else {
        name = 'Unknown';
    }
    return { name: name, version: version };
}
function getExplore(){
    var Sys = {};
    var ua = navigator.userAgent.toLowerCase();
    var s;
    (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1] :
        (s = ua.match(/msie ([\d\.]+)/)) ? Sys.ie = s[1] :
            (s = ua.match(/edge\/([\d\.]+)/)) ? Sys.edge = s[1] :
                (s = ua.match(/firefox\/([\d\.]+)/)) ? Sys.firefox = s[1] :
                    (s = ua.match(/(?:opera|opr).([\d\.]+)/)) ? Sys.opera = s[1] :
                        (s = ua.match(/chrome\/([\d\.]+)/)) ? Sys.chrome = s[1] :
                            (s = ua.match(/version\/([\d\.]+).*safari/)) ? Sys.safari = s[1] : 0;
    // 根据关系进行判断
    if (Sys.ie) return ('IE: ' + Sys.ie);
    if (Sys.edge) return ('EDGE: ' + Sys.edge);
    if (Sys.firefox) return ('Firefox: ' + Sys.firefox);
    if (Sys.chrome) return ('Chrome: ' + Sys.chrome);
    if (Sys.opera) return ('Opera: ' + Sys.opera);
    if (Sys.safari) return ('Safari: ' + Sys.safari);
    return 'Unkonwn';
}
function getExploreName(){
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isOpera = userAgent.indexOf("Opera") > -1
        || userAgent.indexOf("OPR") > -1; //判断是否Opera浏览器

    var isQQ = userAgent.indexOf("QQBrowser") > -1; //判断是否QQBrowser浏览器

    var isUC = userAgent.indexOf("UBrowser") > -1; //判断是否UC浏览器

    var isIE = userAgent.indexOf("compatible") > -1
        && userAgent.indexOf("MSIE") > -1; //判断是否IE7~IE10浏览器

    var isIE11 = userAgent.indexOf("compatible") === -1
        && userAgent.indexOf("Trident") > -1; //判断是否IE11浏览器

    var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器

    var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器

    var isSafari = userAgent.indexOf("Safari") > -1
        && userAgent.indexOf("Chrome") === -1; //判断是否Safari浏览器

    var isChrome = userAgent.indexOf("Chrome") > -1
        && (userAgent.indexOf("; Win") > -1 || userAgent.indexOf("Macintosh;") > -1)
        && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

    var is360 = userAgent.indexOf("Chrome") > -1
        && userAgent.indexOf("; WOW") > -1
        && userAgent.indexOf("Safari") > -1; //判断360浏览器


    if (isIE) {
        var reIE = /MSIE (\d+)\.\d+;/;
        // match() 返回一个数组。数组第一项是匹配到的所有文本；数组第二项是正则中小括号匹配到的文本
        var matchReg = userAgent.match(reIE)
        var fIEVersion = matchReg[1];
        if (fIEVersion == 7) {
            return "IE7及其以下";
        } else if (fIEVersion == 8) {
            return "IE8";
        } else if (fIEVersion == 9) {
            return "IE9";
        } else if (fIEVersion == 10) {
            return "IE10";
        } else {
            return "0";
        }//IE版本过低
        return "IE";
    }
    if (isUC) {
        return "UC";
    }
    if (isQQ) {
        return "QQBrowser";
    }
    if (isIE11) {
        return "IE11";
    }
    if (isOpera) {
        return "Opera";
    }
    if (isEdge) {
        return "Edge";
    }
    if (isFF) {
        return "Firefox";
    }
    if (isSafari) {
        return "Safari";
    }
    if (isChrome) {
        return "Chrome";
    }
    if (is360) {
        return "360";
    }
    return "unknown";
}

/*
    x0,y0,左上角坐标
    x1,y1,右下角坐标
    angle,旋转角度
    return 旋转后大盒子宽高，坐标
*/
function outerRectangle(x0, y0, x1, y1, angle) {
    var angle = angle || 0;
    var cx = (x0 + x1) / 2;
    var cy = (y0 + y1) / 2;
    var pt0 = rotatePoint({x: x0, y: y0}, {x: cx, y: cy}, angle);
    var pt1 = rotatePoint({x: x1, y: y0}, {x: cx, y: cy}, angle);
    var pt2 = rotatePoint({x: x1, y: y1}, {x: cx, y: cy}, angle);
    var pt3 = rotatePoint({x: x0, y: y1}, {x: cx, y: cy}, angle);

    var minX = Math.min(pt0.x, pt1.x, pt2.x, pt3.x);
    var minY = Math.min(pt0.y, pt1.y, pt2.y, pt3.y);
    var maxX = Math.max(pt0.x, pt1.x, pt2.x, pt3.x);
    var maxY = Math.max(pt0.y, pt1.y, pt2.y, pt3.y);

    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
    };
}


//画布上下左右对齐，贴边
function elementAlign(item, angle, direction, pageWidth, pageHeight) {
    var x0 = item.x;
    var y0 = item.y;
    var x1 = item.x + item.width;
    var y1 = item.y + item.height;
    var outer = outerRectangle(x0, y0, x1, y1, angle);
    if(direction == 'left') {
        return (outer.width-item.width)/2
    }
    if(direction == 'right') {
        return pageWidth - item.width - (outer.width - item.width)/2
    }
    if(direction == 'top') {
        return (outer.height-item.height)/2
    }
    if(direction == 'bottom') {
        return pageHeight - item.height - (outer.height - item.height)/2
    }
}

/*
    计算旋转后的元素外框四点坐标
    x0,y0,左上角坐标
    x1,y1,右下角坐标
    angle,旋转角度
    return 旋转后大盒子宽高，坐标
*/
function outerRectangle2(x0, y0, x1, y1, angle) {
    var angle = angle || 0;
    var cx = (x0 + x1) / 2;
    var cy = (y0 + y1) / 2;
    var pt0 = rotatePoint({x: x0, y: y0}, {x: cx, y: cy}, angle);
    var pt1 = rotatePoint({x: x1, y: y0}, {x: cx, y: cy}, angle);
    var pt2 = rotatePoint({x: x1, y: y1}, {x: cx, y: cy}, angle);
    var pt3 = rotatePoint({x: x0, y: y1}, {x: cx, y: cy}, angle);

    var minX = Math.min(pt0.x, pt1.x, pt2.x, pt3.x);
    var minY = Math.min(pt0.y, pt1.y, pt2.y, pt3.y);
    var maxX = Math.max(pt0.x, pt1.x, pt2.x, pt3.x);
    var maxY = Math.max(pt0.y, pt1.y, pt2.y, pt3.y);

    return {
        minX: minX,
        minY: minY,
        maxX: maxX,
        maxY: maxY
    };
}


// 多元素水平垂直居中对齐
function horizontalAndVerticalCenter(attr, all){
    var allX = [];
    var allY = [];
    all.forEach(function(box){
        var outer = outerRectangle2(box.geometry.x, box.geometry.y, box.geometry.x + box.geometry.width, box.geometry.y + box.geometry.height, box.rotation.angle);
        allX.push(outer.minX);
        allY.push(outer.minY);
        allX.push(outer.maxX);
        allY.push(outer.maxY);
    })
    var minX = Math.min.apply(null, allX);
    var minY = Math.min.apply(null, allY);
    var maxX = Math.max.apply(null, allX);
    var maxY = Math.max.apply(null, allY);
    if (attr == 'vertical') {
        return maxY - (maxY - minY)/2;
    }
    if (attr == "standard") {
        return maxX - (maxX - minX)/2;
    }
}
