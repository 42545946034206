(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('SalesOrderViewController', SalesOrderViewController);

    SalesOrderViewController.$inject = ['$q', '$state', '$scope', 'entity', 'SalesOrder', 'PlatformWechatSetting', 'AfterSale',
     'ServiceWxJS', 'Product', 'MessageService', 'OrderExpressHistory', '$localStorage', 'Store', '$stateParams', 'Customer', 'StoreConfig', '$uibModal', 'Editors'];

    function SalesOrderViewController($q, $state, $scope, entity, SalesOrder, PlatformWechatSetting, AfterSale,
                                      ServiceWxJS, Product, MessageService, OrderExpressHistory, $localStorage, Store, $stateParams, Customer, StoreConfig, $uibModal, Editors) {

        var vm = this;
        vm.salesOrder = entity;
        console.log(vm.salesOrder);
        vm.storeId = $stateParams.storeId || $cookieStore.get("store-id");
        vm.store = Store.get({id:vm.storeId});
        $scope.domain = $localStorage.domain;
        vm.tryEdit = $stateParams.tryEdit;
        vm.current = Customer.current();
        vm.goList = goList;
        vm.shareDiv = shareDiv;
        vm.wechartPayState = wechartPayState;
        vm.requireUserProtocol = false;
        vm.rework = rework;
        vm.submittable = false;
        if ($scope.$parent && $scope.$parent.fromState && $scope.$parent.fromState.name != "sales-order-reorder"&& $scope.$parent.fromState.name != "commodity") {
            vm.tryEdit = "false";
        }

        init();

        function init() {
            StoreConfig.byStoreIdAndType({storeId:vm.storeId, type:"USERPROTOCOL"}, function (res) {
                if (res && res.type == "USERPROTOCOL") {
                    vm.requireUserProtocol = res.open || false;
                }
            });
        }

        $scope.createArtcle = function(productLine){
            Editors.navigateSelect(vm.storeId, {
                editorType: productLine.editorType, 
                pid: productLine.productId, 
                vid: productLine.itemValueId, 
                soId: vm.salesOrder.id, 
                plId: productLine.id, 
                selectState: 'productXmlSelect'
            });
            // $state.go('productXmlSelect', {storeId:vm.storeId, plid:productLine.id, vid:productLine.itemValueId, orderId:vm.salesOrder.id, pid:productLine.productId})
        }

        function rework(order){
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确定',
                msg :'确认撤回生产？'
            }, function() {
                SalesOrder.revertSubmit({
                    id: order.id
                }, function(data) {
                    MessageService.success("撤回订单成功");
                    $state.reload();
                }, function() {
                    MessageService.error("撤回订单失败");
                })
            })
        }

        $scope.allIP = function(order){
            if (order == null || order.productLines == null || order.productLines.length == 0) {
                return false;
            }
            for (var i = order.productLines.length - 1; i >= 0; i--) {
                var line = order.productLines[i];
                if (line.productType != 'IPDerive') {
                    return false;
                }
            }
            return true;
        }


        $scope.submit = function(){
            var order = vm.salesOrder;
            if (order == null || order.productLines == null || order.productLines.length == 0 || order.orderLines == null || order.orderLines.length == 0) {
                MessageService.error("作品数量与订单不匹配，提交失败");
                return;
            }
            
            for (var i = order.productLines.length - 1; i >= 0; i--) {
                var productLine = order.productLines[i]
                var copy = productLine.copy;
                for (var j = order.orderLines.length - 1; j >= 0; j--) {
                    var orderLine = order.orderLines[j];
                    if (orderLine.productLineId == productLine.id) {
                        copy = copy - orderLine.copy;
                    }
                }
                if (copy!= 0) {
                    MessageService.error("作品数量与订单不匹配，提交失败");
                    return;
                }
            }
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确定',
                msg :'确认提交订单？'
            }, function() {
                if (vm.store.orderWay == 'orderThenEditor' && vm.requireUserProtocol){

                    checkAgreement(order, function() {
                        requestSubmit(order);
                    });
                    return;
                } else {
                    requestSubmit(order);
                }
            });
        }

        function requestSubmit(order) {
            SalesOrder.submit({
                id: order.id
            }, function(data) {
                MessageService.success("提交订单成功");
                vm.salesOrder.status = "Unchecked";
                vm.submittable = false;
            }, function() {
                MessageService.error("提交订单失败");
            })
        }

        function checkAgreement(order, agreeCallback) {
            var quantity = 0;
            for (var i = 0; i < order.productLines.length; i++) {
                var line = order.productLines[i];
                quantity += (line.copy || 0);
            }
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/modal/protocol-agreement.html',
                controller: 'OrderProtocolAgreementController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        var _obj = {
                            number: order.number,
                            quantity: quantity
                        }
                        return _obj;
                    }]
                }
            }).result.then(function (data) {
                if (data && data.agree) {
                    if (agreeCallback) {
                        agreeCallback();
                    }
                }
            });
        }

        $scope.managerArticle =  function(  lineId){
            if (  lineId == null) {
                return;
            }
            $state.go("sales-order-manager", {id:vm.salesOrder.id, plid:lineId, storeId: vm.storeId});
        }

        function wechartPayState() {
            vm.wechartPayShow = !vm.wechartPayShow;
            if (vm.wechartPayShow) {
                ServiceWxJS.payConfig({
                    id: vm.salesOrder.id,
                    storeId: vm.storeId,
                    totalFee: vm.salesOrder.thirdAmount,
                    type:"web",
                    names:vm.salesOrder.productName
                }, function(result){
                    if (result.status!= 200) {
                        MessageService.error("获取支付信息失败!");
                        return;
                    }
                    var data = result.message;
                    console.log(result);
                    $('#qrcode2').html("");
                    // 支付二维码
                    $('#qrcode2').qrcode({
                        render: "canvas",
                        width: 176,
                        height: 176,
                        text: result.message.url
                    });
                }, function(){
                    MessageService.error("获取支付信息失败");
                    return;
                });
            } else {

            }
        }

        vm.shareFlag = false;
        function shareDiv(line) {
            if (!vm.salesOrder.orderLines || !vm.salesOrder.orderLines[0] || !vm.salesOrder.orderLines[0].orderArticle) {
                MessageService.error("产品信息不全，无法预览");
                return;
            }
            var orderArticle = vm.salesOrder.orderLines[0].orderArticle;

            if ( orderArticle.productType == 'StandardPrint' ||  orderArticle.productType == 'Standard'||vm.salesOrder.sourceType == 'SUBSTITUTION' ) {
                MessageService.error("本客户端暂不支持该品类预览，请在制作端预览");
                return;
            }
            $('#qrcode1').empty()
            var orderArticle = line.orderArticle;
            var text = vm.platformSetting.editorNotifyUrl + "/#/" + vm.salesOrder.storeId + "/" + orderArticle.articleId + "/view/"+orderArticle.id;
            if(Editors.belongToEditorAll(orderArticle.editorType)){
                text = vm.platformSetting.editorNotifyUrl + "/#/" + vm.salesOrder.storeId + "/" + orderArticle.articleId + "/viewShare?customerId=" + vm.current.id + "&type=WECHAT"
            }
            if(orderArticle.editorType == "PICTURE"){
                text = vm.platformSetting.editorNotifyUrl + "/#/" + vm.salesOrder.storeId + "/" + orderArticle.articleId + "/editorPhotoPreview1";
            }
            // 分享二维码
            $('#qrcode1').qrcode({
                render : "canvas",
                width: 150,
                height: 150,
                text: text
            });

            vm.shareFlag = true;
        }

        $scope.pageView2 = function(line){
            if (!line || !line.orderArticle) {
                MessageService.error("产品信息不全，无法预览");
                return;
            }
            var orderArticle = line.orderArticle;
            if ( orderArticle.productType == 'StandardPrint' ||  orderArticle.productType == 'Standard' ||vm.salesOrder.sourceType == 'SUBSTITUTION' ) {
                MessageService.error("本客户端暂不支持该品类预览，请在制作端预览");
                return;
            }
            $state.go("page-view2",{storeId: vm.storeId, orderArticleId: orderArticle.id} );
        }


        vm.proShow = false;
        $scope.productOn = function () {
            vm.proShow = !vm.proShow;
        }

        $(".navbar").show();
        $(".wrap").show().css({'margin': 'margin: 48px 0 0 130px;'});

        function goList() {
            $state.go("sales-order", {storeId: vm.salesOrder.storeId});
        }

        $scope.getProductLineQuantity = function(productLine){
            if (productLine == null) {
                return;
            }
            var orderLinequantity = 0;
            var unCompletedQuantity = 0;
            var completedQuantity = 0;
            if (vm.salesOrder.orderLines!= null) {
                for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.orderLines[i];
                    if (line.orderArticle && line.productLineId == productLine.id) {
                        orderLinequantity = orderLinequantity + line.copy;
                        if (!line.orderArticle.completed) {
                            unCompletedQuantity= unCompletedQuantity + line.copy;
                        }else{
                            completedQuantity = completedQuantity+ line.copy;
                        }
                    }
                }
            }
            productLine.orderLinequantity = orderLinequantity;
            productLine.unCompletedQuantity = unCompletedQuantity;
            productLine.completedQuantity = completedQuantity;
        }

        vm.platformSetting = PlatformWechatSetting.get();
        $q.all([vm.platformSetting.$promise , vm.store.$promise, vm.salesOrder.$promise]).then(function(){
            vm.submittable = vm.salesOrder.submittable;
            vm.deductionArray = [];
            if (vm.salesOrder.orderWay == "orderThenEditor") {
                for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.productLines[i];
                    $scope.getProductLineQuantity(line);
                    line.giftVouchers.forEach(function(item){
                        vm.deductionArray.push(item.code)
                    });
                    line.payCodes.forEach(function(item){
                        vm.deductionArray.push(item.code)
                    })
                }
            }else{
                for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.orderLines[i];
                    line.giftVouchers.forEach(function(item){
                        vm.deductionArray.push(item.code)
                    });
                    line.payCodes.forEach(function(item){
                        vm.deductionArray.push(item.code)
                    })
                }
            }
            getCountDown(vm.salesOrder,new Date(vm.salesOrder.createdDate).getTime(),vm.store.orderDelayCancel);

            if (vm.salesOrder.status == 'Receiving' || vm.salesOrder.status == 'Received' || vm.salesOrder.status == 'Completed') {
                vm.expressHistories = [];
                OrderExpressHistory.byOrderId( {orderId:vm.salesOrder.id} , function(data){
                    if (data && data.length>0) {
                        for (var i = data.length - 1; i >= 0; i--) {
                            var d = data[i];
                            // if (d.traces) {
                            //     d.traces = d.traces.reverse();
                            // }
                            vm.expressHistories.push(d);
                        };
                    }
                });
            }

            AfterSale.getStateByOrder({orderId: vm.salesOrder.id}, function(data){
                console.log(data.message);
                if (data.message == 'null' || data.message == 'Finish') {
                    vm.afterSaleType = true;
                } else {
                    vm.afterSaleType = false;
                }
            })
            if (vm.salesOrder.orderWay == "orderThenEditor" && vm.tryEdit =="true" && vm.salesOrder.status == "Submitted") {
                if ($scope.$parent && $scope.$parent.fromState && ($scope.$parent.fromState.name =="productXmlSelect")) {
                    return;
                }
                var editorLines = [];
                for (var i = vm.salesOrder.productLines.length - 1; i >= 0; i--) {
                    var line = vm.salesOrder.productLines[i];
                    if (line.productType == 'CustomPrint') {
                        editorLines.push(line);
                    }
                }
                if (editorLines.length == 1 && (vm.salesOrder.orderLines == null || vm.salesOrder.orderLines.length ==0 )) {
                    $scope.createArtcle(editorLines[0]);
                    return;
                }
            }
        });



        //支付剩余时间倒计时
        /**
         *
         * @param orderDate 单生效时间，后台的Date类型传到前端成了毫秒
         * @param orderDelayCancel
         */
        function getCountDown(order,orderDate,orderDelayCancel) {
            var now = new Date();
            //提交订单的时间+30min就是订单失效时间，后台Date传过来变成了毫秒
            var end = new Date(orderDate + orderDelayCancel * 60 * 1000);

            /*两个时间相减,得到的是毫秒ms,变成秒*/
            var result = Math.floor(end - now) / 1000; //result= 30*60s;
            var interval = setInterval(sub, 1000); //定时器 调度对象
            /*封装减1秒的函数*/
            function sub() {
                if (result > 1) {
                    result = result - 1;
                    // 计算秒 ，取余
                    var second = Math.floor(result % 60);
                    if(second<10){
                        second="0"+second;
                    }
                    //计算分 ，换算有多少分，取余，余出多少秒
                    var minite = Math.floor((result / 60) % 60);
                    if(minite<10){
                        minite="0"+minite;
                    }
                    //计算小时
                    var hour = Math.floor(result/1000/60/60%24);
                    if(hour<10){
                        hour="0"+hour;
                    }
                    //计算天
                    // var day = Math.floor(result/1000/60/60/24);
                    // if(day<10){
                    //     day="0"+day;
                    // }
                    order.remainTime =hour+"小时"+minite + "分钟" + second + "秒";
                    $scope.$digest();
                } else {
                    //这里可以添加倒计时结束后需要执行的事件
                    // console.log("未在规定时间内支付，订单已失效！");
                    order.remainTime =0;
                    window.clearInterval(interval);
                    $scope.$digest();
                }
            };
        }
        $scope.applyForAfterSales = function(storeId,salesOrder){
            $uibModal.open({
                templateUrl: 'app/entities/after-sale/after-sale-new.html',
                controller: 'AfterSaleNewController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('after-sale');
                        $translatePartialLoader.addPart('salesOrder');
                        return $translate.refresh();
                    }],
                    entity: function () {
                                return {
                                    photo: null,
                                    type: null,
                                    problem: null,
                                    storeId: storeId,
                                    describe: null,
                                    salesOrder: salesOrder
                                };
                            },
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'sales-order',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            }).result.then(function() {
                $state.reload();
            }, function() {

            });
        }

        vm.cancelOrder = function(salesOrder){
            if (salesOrder == null) {
                return;
            };
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确定',
                msg :'确认要取消该订单吗？'
            }, function() {
                if (salesOrder.status == 'Created') {
                    SalesOrder.cancel({
                        id: salesOrder.id
                    }, function(data) {
                        $state.reload();
                    }, function() {
                        MessageService.error("订单取消失败");
                    })
                 }else if(salesOrder.status == 'Unchecked' || salesOrder.status == 'CheckedError' || salesOrder.status == 'Submitted'){
                    SalesOrder.reject({
                        id: salesOrder.id
                    }, function(data) {
                        $state.reload();
                    }, function() {
                        MessageService.error("订单取消失败");
                    })
                 }

            })
        }
        $scope.goProductDetails = function(product){
            $state.go('standardCommodityDetails', {storeId: vm.storeId, pid: product.productId, from:'salesOrder'});
        }
    }
})();
