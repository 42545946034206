(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductView2Controller', ProductView2Controller);

    ProductView2Controller.$inject = ['$scope', '$stateParams', 'entity', '$q', '$localStorage', 'Qinius', 'Store', '$timeout',
        'ImageService', '$http', '$rootScope', 'FontManagement', 'WxJS', 'Theme', 'ItemValue', 'PlatformWechatSetting', 'Customer', 'StoreConfig', 'PreviewSettings'];
    function ProductView2Controller($scope, $stateParams, entity, $q, $localStorage, Qinius, Store,$timeout,
                                    ImageService, $http, $rootScope, FontManagement, WxJS, Theme, ItemValue, PlatformWechatSetting, Customer, StoreConfig, PreviewSettings) {
        var vm = this;
        //风格切换  引入不同的css文件
        Theme.judge();
        vm.platformSetting = PlatformWechatSetting.get();
        var audio = document.getElementById("audio");
        // audio.src = "content/ihope.mp3";
        audio.crossOrigin = 'anonymous';

        var timer = null;
        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');
        vm.storeId = $stateParams.storeId != null ?$stateParams.storeId: $localStorage.storeId;
        vm.aid = $stateParams.aid;
        vm.customerId = $stateParams.customerId;
        vm.type = $stateParams.type;
        vm.article = angular.copy(entity);
        PreviewSettings.byStoreId({storeId: vm.storeId},function (res) {
            vm.defaultbg = res.message.default;
            if(!res.message.default){
                vm.bcImg = res.message.bcImg;
            }
        });

        vm.store = Store.get({id: vm.storeId});
        $scope.dHeight = vm.dHeight = window.innerHeight != null? window.innerHeight :(document.documentElement.clientHeight!= null ?document.documentElement.clientHeight:document.body.clientHeight );
        $scope.dWidth = vm.dWidth = window.innerWidth != null ?  window.innerWidth: (document.documentElement.clientWidth != null ? document.documentElement.clientWidth : document.body.clientWidth);

        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth,'background':'#fff'});
        var $li = $(".templateView2-carousel");
        $li.hide();
        $scope.domains = Qinius.getDomain1({storeId: vm.storeId});
        vm.storeConig = StoreConfig.byStoreIdAndType({type:'SHAREQRCODE'});

        function Play() {
            audio.play().then(function () {
                $(".PanelRight").removeClass('hide1');
                timer = window.setInterval(begin, 10);
            }).catch(function (error) {
            });
        }

        var rot = 10 - 0;
        function begin() {
            var micon = document.getElementById("music-icon");
            if (micon == null) {
                window.clearInterval(timer);
                return;
            }
            micon.style.transform = "rotate(" + rot + "deg)";
            rot += 1;
        }

        $("#Play").on('click', function () {
            window.clearInterval(timer);
            if (audio.paused) {
                if ($(this).children().hasClass('play')) {
                    $("#Play").children("img").removeClass("play").addClass("pause");
                    Play();
                }
            }
            else {
                $("#Play").children("img").removeClass("pause").addClass("play");
                audio.pause();
            }
        });

        function makeCode() {
            $timeout(function () {
                var qrcode = new QRCode(document.getElementById("qrcode"), {
                    width: 100,
                    height: 100
                });
                if(vm.branchStoreId){
                    qrcode.makeCode(vm.platformSetting.storeNotifyUrl + "/#/" + vm.storeId + "/" + vm.branchStoreId + "/" + vm.customerId + "/branchStore/index");
                }else{
                    qrcode.makeCode(vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/index');
                }
            })
        }

        getAll();
        function getAll() {
            vm.itemValue = ItemValue.get({id: vm.article.itemValueId});
            $q.all([$scope.domains.$promise, vm.article.$promise, vm.platformSetting.$promise, vm.store.$promise, vm.itemValue.$promise]).then(function () {
                if (vm.type == 'WECHAT') {
                    $scope.miniType = false;
                    Customer.get({id: vm.customerId}, function (res) {
                        vm.branchStoreId = res.branchStoreId;
                        makeCode();
                    })
                }else{
                    $scope.miniType = true;
                    $http({
                        url: "/fileserves/api/download/getMiniQrCode",
                        method: 'get',
                        params:{
                            storeId: vm.storeId,
                            customerId: vm.customerId
                        },
                        responseType: 'blob'
                    }).success(function(res) {
                        var blob = new Blob([res], {
                            type: "image/jpeg"
                        });
                        $scope.image = URL.createObjectURL(blob);
                    })
                }
                $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                if (vm.article.musicIdentify) {
                    audio.src = $scope.domain + vm.article.musicIdentify;
                }else{
                    if (vm.itemValue.musicIdentify) {
                        audio.src = $scope.domain + vm.itemValue.musicIdentify;
                    } else {
                        audio.src = '';
                    }
                }

                if (wx) {
                    var url = "";
                    if (!!window.location.hash ) {
                        url = window.location.href.slice(0, -window.location.hash.length)
                    }else{
                        url =  window.location.href;
                    }

                    WxJS.get2({
                        url: url,storeId:vm.storeId
                    }, function (config) {
                        if (window.location.href.indexOf("?") == -1) {
                            wx.config({
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                appId: config.appId, // 必填，公众号的唯一标识
                                timestamp: config.timestamp, // 必填，生成签名的时间戳
                                nonceStr: config.nonceStr, // 必填，生成签名的随机串
                                signature: config.signature,// 必填，签名，见附录1
                                jsApiList: [
                                    "checkJsApi",
                                    "onMenuShareTimeline",
                                    "onMenuShareAppMessage",
                                    "onMenuShareQQ",
                                    "onMenuShareQZone",
                                    'showOptionMenu'
                                ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                            });

                            // 异步处理，方法堆放一起
                            wx.ready(function () {
                                // 自动播放音乐
                                // Play();
                                WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
                                    Play();
                                });

                                wx.showOptionMenu();

                                wx.checkJsApi({
                                    jsApiList: [
                                        "checkJsApi",
                                        "onMenuShareTimeline",
                                        "onMenuShareAppMessage",
                                        "onMenuShareQQ",
                                        "onMenuShareQZone"
                                    ],
                                    success: function (res) {
                                        wx.onMenuShareTimeline({
                                            title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                            link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/view2/0", // 分享链接
                                            imgUrl: $scope.domain + vm.article.cover,
                                            desc: '随意创作属于自己独一无二的作品',
                                            success: function (res) {
                                            },
                                            cancel: function (res) {
                                            },
                                            fail: function (res) {
                                            }
                                        });
                                        //朋友
                                        wx.onMenuShareAppMessage({
                                            title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                            desc: '随意创作属于自己独一无二的作品', // 分享描述
                                            link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/view2/0", // 分享链接
                                            imgUrl: $scope.domain + vm.article.cover,
                                            type: 'link', // 分享类型,music、video或link，不填默认为link
                                            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                                            success: function () {
                                            },
                                            cancel: function () {
                                            }
                                        });
                                        wx.onMenuShareQQ({
                                            title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                            desc: '随意创作属于自己独一无二的作品', // 分享描述
                                            link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/view2/0", // 分享链接
                                            imgUrl: $scope.domain + vm.article.cover, // 分享图标
                                            success: function () {
                                                // 用户确认分享后执行的回调函数
                                            },
                                            cancel: function () {
                                                // 用户取消分享后执行的回调函数
                                            }
                                        });
                                        wx.onMenuShareQZone({
                                            title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                            desc: '随意创作属于自己独一无二的作品', // 分享描述
                                            link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/view2/0", // 分享链接
                                            imgUrl: $scope.domain + vm.article.cover, // 分享图标
                                            success: function () {
                                                // 用户确认分享后执行的回调函数
                                            },
                                            cancel: function () {
                                                // 用户取消分享后执行的回调函数
                                            }
                                        });
                                    }
                                });
                            });
                        }else{
                            wx.config({
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                appId: config.appId, // 必填，公众号的唯一标识
                                timestamp: config.timestamp, // 必填，生成签名的时间戳
                                nonceStr: config.nonceStr, // 必填，生成签名的随机串
                                signature: config.signature, // 必填，签名，见附录1
                                jsApiList: ['hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                            });
                            wx.ready(function () {
                                wx.hideMenuItems({
                                    menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                                });

                                // 自动播放音乐
                                Play();
                            });
                        }

                        wx.error(function (res) {
                        });
                    }, function () {
                    });
                }
                document.addEventListener('visibilitychange', startStopBgMedia);
                function startStopBgMedia() {
                    if (document.visibilityState === 'hidden') {
                        //切到后台
                        if (audio.play) {
                            audio.pause();
                        }
                    } else if (document.visibilityState === 'visible') {
                        //切到前台
                        if (audio.paused) {
                            Play();
                        }
                    }
                }

            });
        }

        //旋转屏幕
        window.addEventListener('resize', function (event) {
            if (window.orientation == 0) {
                $rootScope.orientations = false;
            }
            setReviewDoc();
        });

        if (window.orientation == 90 || window.orientation == -90) {
            $li.width($scope.dWidth);
            $rootScope.orientation = false;
        } else {
            $rootScope.orientations = true;
        }

        function setReviewDoc() {
            $(".save-page").removeClass('hide1');
            $(".view2-article").addClass('hide1');
            $(".share-wrap").addClass('hide1');
            setTimeout(function () {
                if (window.orientation == 180 || window.orientation == 0) {
                    $rootScope.orientations = true;
                    orientationChange();

                } else if (window.orientation == 90 || window.orientation == -90) {
                    $rootScope.orientations = false;
                    orientationChange();
                }
            }, 500);
        }

        function orientationChange() {
            $scope.k = 0;
            $scope.dHeight = document.documentElement.clientHeight;
            $scope.dWidth = document.documentElement.clientWidth;
            $(".back-to-customer").width($scope.dWidth);
            $li.width($scope.dWidth);
            vm.article = angular.copy(entity);
            getAll();
            setTimeout(function () {
                $(".save-page").addClass('hide1');
                $(".view2-article").removeClass('hide1');
                $(".share-wrap").removeClass('hide1');
            }, 1500);
        }
    }
})();
