(function() {
    'use strict';
    angular
        .module('editorApp')
        .factory("Region",Region)
        .factory("StorePickLocation",StorePickLocation)
        .factory("PayCodes",PayCodes)
        .factory("ProductPurchase",ProductPurchase)
        .factory('StorePaymentSetting', StorePaymentSetting)
        .factory("OrderExpressHistory",OrderExpressHistory)
        .factory("ItemValueLinePackage", ItemValueLinePackage)
        .factory('SalesOrder', SalesOrder)
        .factory('Histories', Histories);

    SalesOrder.$inject = ['$resource'];
    ItemValueLinePackage.$inject = ['$resource'];
    OrderExpressHistory.$inject = ['$resource'];
    PayCodes.$inject = ['$resource'];
    StorePickLocation.$inject = ['$resource'];
    ProductPurchase.$inject = ['$resource'];
    Region.$inject = ['$resource'];
    Histories.$inject = ['$resource'];

    StorePaymentSetting.$inject = ['$resource'];

    function Histories ($resource) {
        var resourceUrl ="service/api/histories/:path/:id";

        return $resource(resourceUrl, {}, {
            'errorLog': {
                method: 'POST',
                params: {
                    path: 'errorLog'
                },
            }
        });
    }
    function StorePaymentSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/store-payment-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCurrent': {
                method: 'GET',
                params: {
                    path: 'getCurrent'
                },
            },
            'update': { method:'PUT' }
        });
    }

    function ItemValueLinePackage ($resource){
        var resourceUrl ="manager/api/item-value-line-packages/:path/:id";
        return $resource(resourceUrl,{},{
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "checkPackagesStockQuantity":{
                method:"POST",
                params:{
                    path:"checkPackagesStockQuantity"
                }
            }
        })
    }

    function OrderExpressHistory ($resource){
        var resourceUrl ="service/api/order-express-histories/:path/:id";
        return $resource(resourceUrl,{},{
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "byOrderId": {
                method: 'GET',
                params: {
                    path: 'byOrderId'
                },
                isArray: true
            }
        })
    }

    function ProductPurchase ($resource){
        var resourceUrl ="manager/api/product-purchases/:path/:id";
        return $resource(resourceUrl,{},{
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        })
    }

    function Region ($resource){
        var resourceUrl ="manager/api/regions/:path/:id";
        return $resource(resourceUrl,{},{
            
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            }
        })
    }

    function StorePickLocation ($resource) {
        var resourceUrl =  'manager/' + 'api/store-pick-locations/:path';
        return $resource(resourceUrl, {}, {
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray:true
            },
        });
    }

    function PayCodes ($resource) {
        var resourceUrl =  'service/' + 'api/pay-codes/:path/:code';

        return $resource(resourceUrl, {}, {
            "bindUser":{
                method:"GET",
                params:{
                    path:"bindUser"
                }
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                },
                isArray:true
            },
            "getAllByCondition3":{
                method:"POST",
                params:{
                    path:"getAllByCondition3"
                },
                isArray:true
            },
            "payCodeAvailable":{
                method:"POST",
                params:{
                    path:"payCodeAvailable"
                },
                isArray:true
            },
            "codeAvailable2":{
                method:"POST",
                params:{
                    path:"codeAvailable2"
                },
                isArray:true
            }
        });
    }

    function SalesOrder ($resource) {
        var resourceUrl =  'service/' + 'api/sales-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            "constructBuyAgain":{
                method:"GET",
                params:{
                    path:"constructBuyAgain"
                }
            },
            "getDeliveryInfo":{
                method:"GET",
                params:{
                    path:"getDeliveryInfo"
                }
            },
            "getDeliveryInfo2":{
                method:"GET",
                params:{
                    path:"getDeliveryInfo2"
                }
            },
            "getBySourceNumber":{
                method:"GET",
                params:{
                    path:"getBySourceNumber"
                }
            },
            "requireRefund":{
                method:"GET",
                params:{
                    path:"requireRefund"
                }
            },
            "createBySourceNumber":{
                method:"POST",
                params:{
                    path:"createBySourceNumber"
                }
            },
            "createBySourceNumber3":{
                method:"POST",
                params:{
                    path:"createBySourceNumber3"
                }
            },

            "revertSubmit":{
                 method:"GET",
                params:{
                    path:"revertSubmit"
                }
            },
            "updateBySourceNumber":{
                method:"POST",
                params:{
                    path:"updateBySourceNumber"
                }
            },
            "checkout2":{
                method:"GET",
                params:{
                    path:"checkout2"
                }

            },
            "createSelfOrder":{
                method:"POST",
                params:{
                    path:"createSelfOrder"
                }
            },

            "complete":{
                method:"GET",
                params:{
                    path:"complete",
                }
            },
            "afterSale":{
                method: "GET",
                params:{
                    path: "afterSale",
                }
            },
            "getCost2":{
                method: "POST",
                params:{
                    path: "getCost2",
                }
            },
            "received": {
                method: "GET",
                params:{
                    path: "received",
                }
            },
            "cancel":{
                method:"GET",
                params:{
                    path:"cancel",
                }
            },
            "payCodePay2":{
                method:"POST",
                params:{
                    path:"payCodePay2"
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                },
                isArray: true
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                },
                isArray: true
            },
            "construct2":{
                method:"GET",
                params:{
                    path:"construct2"
                }
            },
            "submittable":{
                method:"GET",
                params:{
                    path:"submittable"
                }
            },
            "constructImmediately2":{
                method:"GET",
                params:{
                    path:"constructImmediately2"
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getOrderNumByStatus': {
                method: 'GET',
                params: {
                    path: "getOrderNumByStatus"
                }
            },
            'reject': {
                method: 'GET',
                params: {
                    path: "reject"
                }
            },
            'deleteArticle': {
                method: 'GET',
                params: {
                    path: "deleteArticle"
                }
            },
            'getAllNumByStoreAndCustomer': {
                method: 'GET',
                params: {
                    path: "getAllNumByStoreAndCustomer"
                }
            },
            'updateByArticle': {
                method: 'GET',
                params: {
                    path: "updateByArticle"
                }
            },
            'updateArticle': {
                method: 'GET',
                params: {
                    path: "updateArticle"
                }
            },
            "createBySourceNumber2":{
                method:"GET",
                params:{
                    path:"createBySourceNumber2"
                }
            },
            'submit': {
                method: 'GET',
                params: {
                    path: "submit"
                }
            }
        });
    }

})();
